<template>
  <div class="row m-0">
    <div class="col-12 p-0">
      <div class="container-xxl" >
        <PageTitle :title="$route.name" />
        <div class="row  justify-content-center page_top">
          <div class="col-12 p-0">
            <div class="row mx-0 mb-3 justify-content-center">
              <div class="col-11 col-sm-9 col-md-7 col-lg-6 col-xl-5 col-xxl-4 text-center p-0 my-4 page_heading">
                Ways to Donate
              </div>
            </div>
            <div class="row align-items-start" v-if="isUK">
              <div class="col-md-6 mb-4 h-100">
                <WaysBoxComponent title="Donation Line" leftText="Call us on our donation line for donations, appeals, information, queries and more at:">
                  <template v-slot:icon>
                    <IconPhone color="gold-text" size="size45" />
                  </template>
                  <template v-slot:main>
                    <div class="row">
                      <div class="col-12 mt-2 mb-3">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0 mt-1">
                            <IconPhone color="gold-text" size="size16" />
                          </div>
                          <div class="col-auto col-md medium dark pe-0">
                            +44 (0)1254 698771
                          </div>
                        </div>
                      </div>
                      <div class="col-12 text-center justify-content-center">
                        <div class="row">
                          <div class="col-6">
                            Monday - Friday
                          </div>
                          <div class="col-6">
                            9:00 AM - 5:00 PM
                          </div>
                        </div>
                      </div>
                      <div class="col-12 p-0 green-text text-center text-md-start mt-3">
                        <Button btnText="Call now" icon="arrow" size="smallish" @buttonClicked="call(+441254698771)">
                          <IconArrowForward color="green" size="size16" />
                        </Button>
                      </div>
                    </div>
                  </template>
                </WaysBoxComponent>
              </div>
              <div class="col-md-6 mb-4 h-100">
                <WaysBoxComponent title="Cheque" leftText="Ensure cheques are clearly written and sent with project/appeal and donation type.">
                  <template v-slot:icon>
                    <IconCheque color="gold-text" size="size45" />
                  </template>
                  <template v-slot:main>
                    <div class="row">
                      <div class="col-12 mt-2 mb-1">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0 mt-1">
                            <IconBank color="gold-text" size="size16" />
                          </div>
                          <div class="col-auto col-md medium dark pe-0">
                            Payable to: Al-Imdaad Foundation UK
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0 mt-1">
                            <IconLocation color="gold-text" size="size16" />
                          </div>
                          <div class="col-auto col-md  medium dark pe-0">
                            Posted to: 185 Audley Range, Blackburn, BB1 1TH
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </WaysBoxComponent>
              </div>
              <div class="col-md-6 mb-4 h-100">
                <WaysBoxComponent title="Bank Transfer" leftText="Please submit your Bank Donation online so your donation can be processed correctly.">
                  <template v-slot:icon>
                    <IconBank color="gold-text" size="size45" />
                  </template>
                  <template v-slot:main>
                    <div class="row">
                      <div class="col-12 mt-2 mb-1">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0 mt-1">
                            <img src="@/assets/images/al-rayaanbank.png" class="img-fluid img_height" />
                          </div>
                          <div class="col-auto col-md  medium dark pe-0">
                            Al Rayan Bank
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mb-1">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0 mt-1">
                            <IconBankAccountName color="gold-text" size="size16" />
                          </div>
                          <div class="col-auto col-md  medium dark pe-0">
                            Account Number: 01229501
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0 mt-1">
                            <IconBIC color="gold-text" size="size16" />
                          </div>
                          <div class="col-auto col-md  medium dark pe-0">
                            Sort Code: 30-00-83
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </WaysBoxComponent>
              </div>
              <div class="col-md-6 mb-4 h-100">
                <WaysBoxComponent title="International Transfers" leftText="Bank Address: 24a Calthorpe Road, Birmingham, B15 1RP.">
                  <template v-slot:icon>
                    <IconInternational color="gold-text" size="size45" />
                  </template>
                  <template v-slot:main>
                    <div class="row">
                      <div class="col-12 mt-2 mb-1">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0 mt-1">
                            <IconBankAccountName color="gold-text" size="size16" />
                          </div>
                          <div class="col-auto col-md  medium dark pe-0">
                            Name: Al-Imdaad Foundation UK
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mb-1">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0 mt-1">
                            <IconIBAN color="gold-text" size="size16" />
                          </div>
                          <div class="col-auto col-md  medium dark pe-0">
                            IBAN: GB81 ARAY 3000 8301 2295 01
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mb-1">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0 mt-1">
                            <IconBIC color="gold-text" size="size16" />
                          </div>
                          <div class="col-auto col-md  medium dark pe-0">
                            BIC: ARAYGB22
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0 mt-1">
                            <IconBankClearing color="gold-text" size="size16" />
                          </div>
                          <div class="col-auto col-md  medium dark pe-0">
                            Clearing Bank: Lloyds LOYDGB2L.
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </WaysBoxComponent>
              </div>
              <div class="col-md-6 mb-4 h-100">
                <WaysBoxComponent title="JustGiving" leftText="Call us for assistance in setting up your fundraising page today!">
                  <template v-slot:icon>
                    <IconGive color="gold-text" size="size45" />
                  </template>
                  <template v-slot:main>
                    <div class="row">
                      <div class="col-12 mt-2 ">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0 mt-1">
                            <IconBank color="gold-text" size="size16" />
                          </div>
                          <div class="col-auto col-md  medium dark pe-0">
                            <a href="https://www.justgiving.com/al-imdaadfoundation" target="_blank">www.justgiving.com/al-imdaadfoundation</a>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        Donate to JustGiving page and start your own personal fundraising pages.
                      </div>
                      <div class="col-12 p-0 green-text text-center text-md-start mt-3">
                        <Button size="smallish" btnText="Fundraise Now" icon="arrow" @buttonClicked="goTo('https://www.justgiving.com/al-imdaadfoundation')">
                          <IconArrowForward color="green" size="size16" />
                        </Button>
                      </div>
                    </div>
                  </template>
                </WaysBoxComponent>
              </div>
              <!-- <div class="col-md-6 mb-4 h-100">
                <div class="row m-0 card_item">
                  <div class="col-md-5 p-0">
                    <div class="row m-0 holder h-100">
                      <div class="bg_holder">
                        <div class="background"></div>
                      </div>
                      <div class="col-12 p-4 above ">
                        <div class="row m-0 h-100 justify-content-center align-items-center">
                          <div class="col-12 text-center">
                            <IconRound size="xbig" align="center">
                              <IconPayPal color="gold-text" size="size45" />
                            </IconRound>
                            <div class="infoText mt-2">
                              Use the notes box to mention the project/appeal and donation type.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7 p-4">
                    <div class="row">
                      <div class="col-12 smallheading">
                        PayPal
                      </div>
                      <div class="col-12 mt-2 mb-3">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0 mt-1">
                            <IconPayPal color="gold-text" size="size16" />
                          </div>
                          <div class="col-auto col-md  medium dark pe-0">
                            ID: donations@alimdaad.co.uk
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        Enter the email above in your PayPal app or web portal to send donations directly from your PayPal account
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="row mx-0 align-items-start" v-else>
              <div class="col-md-6 mb-4 h-100">
                <WaysBoxComponent title="Donation Line" leftText="Call us on our donation line for donations, appeals, information, queries and more">
                  <template v-slot:icon>
                    <IconPhone color="gold-text" size="size45" />
                  </template>
                  <template v-slot:main>
                    <div class="row">
                      <div class="col-12 mt-2 mb-3">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0 mt-1">
                            <IconPhone color="gold-text" size="size16" />
                          </div>
                          <div class="col-auto col-md  medium dark pe-0">
                            0861786243
                          </div>
                        </div>
                      </div>
                      <div class="col-12 text-center justify-content-center">
                        <div class="row">
                          <div class="col-6">
                            Monday - Friday
                          </div>
                          <div class="col-6">
                            8:00 AM - 5:00 PM
                          </div>
                        </div>
                      </div>
                      <div class="col-12 p-0 green-text text-center text-md-start mt-3">
                        <Button btnText="Call now" icon="arrow" size="smallish" @buttonClicked="call(+27861786243)">
                          <IconArrowForward color="green" size="size16" />
                        </Button>
                      </div>
                    </div>
                  </template>
                </WaysBoxComponent>
                <WaysBoxComponent title="Section 18A" leftText="Donate up to 10% of your taxable income to South African Projects and claim the amount back.">
                  <template v-slot:icon>
                    <Icon18a color="gold-text" size="size45" />
                  </template>
                  <template v-slot:main>
                    <div class="row">
                      <div class="col-12 mt-2">
                        Donate up to 10% of your taxable income to South African Projects and claim the amount back.
                      </div>
                      <div class="col-12 mt-2">
                        <div><b>Bank Details</b></div>
                        <div>First National Bank</div>
                        <div>Account name: FRIENDS OF THE SUFFERING</div>
                        <div>Branch name: ESTCOURT</div>
                        <div>Branch code: 220325</div>
                        <div>Account number: 62102362150</div>
                        <div>Swift code: FIRNZAJJ</div>
                        <div>Reference: Name/Company Name + Zakaat/Lillah/Sadaqah/Etc</div>
                      </div>
                      <div class="col-12 p-0 green-text text-center text-md-start mt-3">
                        <Button size="smallish" btnText="More details and requirements" icon="arrow" @buttonClicked="goToInternal('/section18a')">
                          <IconArrowForward color="green" size="size16" />
                        </Button>
                      </div>
                    </div>
                  </template>
                </WaysBoxComponent>
                <WaysBoxComponent title="Debit Order" leftText="Set up a debit order for a recurring donation">
                  <template v-slot:icon>
                    <IconRecurring color="gold-text" size="size45" />
                  </template>
                  <template v-slot:main>
                    <div class="row">
                      <div class="col-12 mt-2">
                        Set up a debit order for a recurring donation
                      </div>
                      <div class="col-12 p-0 green-text text-center text-md-start mt-3">
                        <Button size="smallish" btnText="Set up now" icon="arrow" @buttonClicked="goToInternal('/debitorders')">
                          <IconArrowForward color="green" size="size16" />
                        </Button>
                      </div>
                    </div>
                  </template>
                </WaysBoxComponent>
                <WaysBoxComponent title="Donation Point" leftText="Donate in person at an Al-Imdaad Foundation office near you">
                  <template v-slot:icon>
                    <IconBank color="gold-text" size="size45" />
                  </template>
                  <template v-slot:main>
                    <div class="row">
                      <div class="col-12 mt-2">
                        Donate in person at an Al-Imdaad Foundation office near you
                      </div>
                      <div class="col-12 p-0 green-text text-center text-md-start mt-3">
                        <Button size="smallish" btnText="Find an office" icon="arrow" @buttonClicked="goToInternal('/contact/#donationPoints')">
                          <IconArrowForward color="green" size="size16" />
                        </Button>
                      </div>
                    </div>
                  </template>
                </WaysBoxComponent>
              </div>
              <div class="col-md-6 mb-4 h-100">
                <WaysBoxComponent title="Bank Transfer" leftText="Please submit your Bank Donation online so your donation can be processed correctly.">
                  <template v-slot:icon>
                    <IconBank color="gold-text" size="size45" />
                  </template>
                  <template v-slot:main>
                    <div class="row">
                      <div class="col-12 mt-2 mb-2">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0">
                            <img src="@/assets/images/fnb.png" class="img-fluid img_height" />
                          </div>
                          <div class="col-auto col-md  medium dark pe-0">
                            First National Bank
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mb-1">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0">
                            <!-- <IconBankAccountName color="gold-text" size="size16" /> -->
                          </div>
                          <div class="col-auto col-md p-0 font14">
                            Account name: <b>AL IMDAAD FOUNDATION</b>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mb-1">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0">
                            <!-- <IconBankAccountName color="gold-text" size="size16" /> -->
                          </div>
                          <div class="col-auto col-md p-0 font14">
                            Branch name: <b>ESTCOURT</b>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mb-1">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0">
                            <!-- <IconBankAccountName color="gold-text" size="size16" /> -->
                          </div>
                          <div class="col-auto col-md p-0 font14">
                            Branch code: <b>220325</b>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mb-1">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0">
                            <!-- <IconBankAccountName color="gold-text" size="size16" /> -->
                          </div>
                          <div class="col-auto col-md p-0 font14">
                            Account number: <b>62102373206</b>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mb-1">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0">
                            <!-- <IconBankAccountName color="gold-text" size="size16" /> -->
                          </div>
                          <div class="col-auto col-md p-0 font14">
                            Swift code: <b>FIRNZAJJ</b>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mb-1">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0">
                            <!-- <IconBankAccountName color="gold-text" size="size16" /> -->
                          </div>
                          <div class="col-auto col-md p-0 font14">
                            Reference: <b>Project + Zakaat / Lillah / Sadaqah</b>
                          </div>
                        </div>
                      </div>
                      <div class="col-12  medium dark font14 green-text">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0">
                          </div>
                          <div class="col-auto col-md p-0">
                            Please email proof of payment to donations@alimdaad.com
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </WaysBoxComponent>
                <WaysBoxComponent title="Bank Transfer" leftText="Please submit your Bank Donation online so your donation can be processed correctly.">
                  <template v-slot:icon>
                    <IconBank color="gold-text" size="size45" />
                  </template>
                  <template v-slot:main>
                    <div class="row">
                      <div class="col-12 mt-2 mb-2">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0">
                            <img src="@/assets/images/nedbank.png" class="img-fluid img_height" />
                          </div>
                          <div class="col-auto col-md  medium dark pe-0">
                            Nedbank
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mb-1">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0">
                            <!-- <IconBankAccountName color="gold-text" size="size16" /> -->
                          </div>
                          <div class="col-auto col-md p-0 font14">
                            Account name: <b>AL IMDAAD FOUNDATION</b>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mb-1">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0">
                            <!-- <IconBankAccountName color="gold-text" size="size16" /> -->
                          </div>
                          <div class="col-auto col-md p-0 font14">
                            Branch name: <b>ESTCOURT</b>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mb-1">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0">
                            <!-- <IconBankAccountName color="gold-text" size="size16" /> -->
                          </div>
                          <div class="col-auto col-md p-0 font14">
                            Branch code: <b>134925</b>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mb-1">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0">
                            <!-- <IconBankAccountName color="gold-text" size="size16" /> -->
                          </div>
                          <div class="col-auto col-md p-0 font14">
                            Account number: <b>1349112402</b>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mb-1">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0">
                            <!-- <IconBankAccountName color="gold-text" size="size16" /> -->
                          </div>
                          <div class="col-auto col-md p-0 font14">
                            Swift code: <b>NEDSZAJJ</b>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mb-1">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0">
                            <!-- <IconBankAccountName color="gold-text" size="size16" /> -->
                          </div>
                          <div class="col-auto col-md p-0 font14">
                            Reference: <b>Project + Zakaat / Lillah / Sadaqah</b>
                          </div>
                        </div>
                      </div>
                      <div class="col-12  medium dark font14 green-text">
                        <div class="row m-0 justify-content-center justify-content-md-start">
                          <div class="col-1 p-0">
                          </div>
                          <div class="col-auto col-md p-0">
                            Please email proof of payment to donations@alimdaad.com
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </WaysBoxComponent>
                <WaysBoxComponent title="Via Our App" leftText="Serve humanity on-the-go with our official app.">
                  <template v-slot:icon>
                    <ios-phone-portrait color="gold-text" class="inline-icon big size45"/>
                  </template>
                  <template v-slot:main>
                    <div class="row">
                      <div class="col-12 mt-2 mb-2">
                        <div class="row mx-0 align-items-center ">
                          <div class="col-auto p-0">
                            <img src="@/assets/images/appstore.svg" class="img-fluid appStore cursor" @click="goAppStore" />
                          </div>
                          <div class="col-auto p-0">
                            <img src="@/assets/images/googleplay.png" class="img-fluid playStore cursor" @click="goPlayStore" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </WaysBoxComponent>
                <WaysBoxComponent title="Via the FNB App" leftText="You can use the FNB App">
                  <template v-slot:icon>
                    <img src="@/assets/images/fnb.png" class="img-fluid " />
                    <!-- <ios-phone-portrait color="gold-text" class="inline-icon big size45"/> -->
                  </template>
                  <template v-slot:main>
                    <div class="row">
                      <div class="col-12 mt-2 mb-2">
                        nav-igate life - nav>> Care - Donate - Make an Islamic Donation - Donation Type - Al-Imdaad Foundation - View Causes
                      </div>
                    </div>
                  </template>
                </WaysBoxComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FundraisersSeparateSection v-if="isUK" />
      <LatestAppealsWithSlider />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import WaysBoxComponent from './components/WaysBoxComponent.vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    LatestAppealsWithSlider: defineAsyncComponent(() => import('@/components/LatestAppealsWithSlider.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    IconBank: defineAsyncComponent(() => import('@/components/icons/IconBank.vue')),
    IconPhone: defineAsyncComponent(() => import('@/components/icons/IconPhone.vue')),
    IconCheque: defineAsyncComponent(() => import('@/components/icons/IconCheque.vue')),
    IconGive: defineAsyncComponent(() => import('@/components/icons/IconGive.vue')),
    IconInternational: defineAsyncComponent(() => import('@/components/icons/IconInternational.vue')),
    IconLocation: defineAsyncComponent(() => import('@/components/icons/IconLocation.vue')),
    IconBankAccountName: defineAsyncComponent(() => import('@/components/icons/IconBankAccountName.vue')),
    IconBIC: defineAsyncComponent(() => import('@/components/icons/IconBIC.vue')),
    IconBankClearing: defineAsyncComponent(() => import('@/components/icons/IconBankClearing.vue')),
    IconIBAN: defineAsyncComponent(() => import('@/components/icons/IconIBAN.vue')),
    Icon18a: defineAsyncComponent(() => import('@/components/icons/Icon18a.vue')),
    IconRecurring: defineAsyncComponent(() => import('@/components/icons/IconRecurring.vue')),
    'ios-phone-portrait': defineAsyncComponent(() => import('vue-ionicons/dist/ios-phone-portrait.vue')),
    WaysBoxComponent,
    FundraisersSeparateSection: defineAsyncComponent(() => import('./views/FundraisersSeparateSection.vue'))
  },
  name: 'Donation Methods',
  computed: {
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    call (val) {
      window.location.href = 'tel:' + val
    },
    goTo (val) {
      window.open(val, '_blank')
    },
    goToInternal (val) {
      window.scroll(0, 0)
      this.$router.push(val)
    },
    goPlayStore () {
      window.open('https://play.google.com/store/apps/details?id=io.resourcedesign.aifapp', '_blank').focus()
    },
    goAppStore () {
      window.open('https://apps.apple.com/za/app/al-imdaad-foundation/id1507938238', '_blank').focus()
    }
  }
}
</script>

<style scoped>

.title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 29px;
  font-weight: 500;
  line-height: 1.3em;
  letter-spacing: 8px;
}
/* .playStore {
  height: 77px;
}
.appStore {
  height: 51.74px;
} */

.playStore {
  height: 52.09px;
}
.appStore {
  height: 35px;
}
</style>
