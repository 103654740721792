<template>
  <div class="row m-0">
    <div class="col-12 p-0">
      <PageTitle :title="$route.name" />
      <div class="row mx-0 justify-content-center page_top">
        <div class="col-12 p-0">
          <div class="row mx-0 my-5 justify-content-center">
            <div class="col-md-5 col-10">
              <Search type="text" color="outline" size="big" placeholder="Search" align="left" id="searchPage" v-model="searchPage"/>
            </div>
          </div>
          <div class="row m-0 justify-content-center">
            <div class="col-md-4 col-10 info_text text-center mb-5">
              <span v-if="isUK">
                Enter a keyword to search for appeals, project updates or news articles.
              </span>
              <span v-else>
                Enter a keyword to search for appeals or project updates.
              </span>
            </div>
          </div>
        </div>
      </div>
      <PageTitle title="SEARCH RESULTS" />
      <div class="row mx-0 justify-content-center mb-5">
        <div class="col-md-4 col-10 p-0 heading text-center my-4">
          Appeals & Project Updates
        </div>
        <div class="col-12 p-0 grey_bg">
          <div class="container-lg" >
            <div class="row my-4">
              <div class="col-lg-4 p-2" v-for="project in filteredProjects.slice(0, showNumberProjects)" :key="project.id">
                <ProjectSearchCard :project="project" :btn="'View Article'"/>
              </div>
            </div>
            <div class="row mx-0 mb-4 justify-content-center" v-if="filteredProjects.length > showNumberProjects">
              <div class="col-auto mb-2">
                <Button color="green" btnText="Load more" icon="arrow" @buttonClicked="showMoreProjects" >
                  <ios-arrow-round-down-icon class="inline-icon"/>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PageTitle title="SEARCH RESULTS" v-if="isUK" />
      <div class="row mx-0 justify-content-center mb-5" v-if="isUK">
        <div class="col-md-4 col-10 p-0 heading text-center my-4">
          News & Articles
        </div>
        <div class="col-12 p-0 grey_bg">
          <div class="container-lg" >
            <div class="row my-4">
              <div class="col-lg-4 p-2" v-for="item in filteredNews.slice(0, showNumberNews)" :key="item.id">
                <NewsSearchCard :newsItem="item" :btn="'View Article'" />
              </div>
            </div>
            <div class="row mx-0 mb-4 justify-content-center" v-if="filteredNews.length > showNumberNews">
              <div class="col-auto mb-2">
                <Button color="green" btnText="Load more" icon="arrow" @buttonClicked="showMoreNews" >
                  <ios-arrow-round-down-icon class="inline-icon"/>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    Search: defineAsyncComponent(() => import('@/components/Search.vue')),
    NewsSearchCard: defineAsyncComponent(() => import('./views/NewsSearchCard.vue')),
    ProjectSearchCard: defineAsyncComponent(() => import('./views/ProjectSearchCard.vue')),
    'ios-arrow-round-down-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-arrow-round-down.vue'))
  },
  name: 'SearchPage',
  data () {
    return {
      searchPage: '',
      showNumberProjects: 6,
      showNumberNews: 6
    }
  },
  methods: {
    showMoreProjects () {
      this.showNumberProjects += 6
    },
    showMoreNews () {
      this.showNumberNews += 6
    }
  },
  computed: {
    ...mapGetters([
      'contentProjects',
      'news'
    ]),
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    },
    filteredProjects () {
      const search = this.searchPage.toLowerCase().trim()
      return this.contentProjects.filter(project => {
        if (search && project.name.toLowerCase().indexOf(search) <= -1) {
          return false
        }
        return true
      })
    },
    filteredNews () {
      const search = this.searchPage.toLowerCase().trim()
      return this.news.filter(item => {
        if (search && item.name.toLowerCase().indexOf(search) <= -1) {
          return false
        }
        return true
      })
    }
  }
}
</script>

<style scoped>
.divider {
  width: 2px;
  height: 100%;
  background-color: var(--green-color-light);
}
.info_text {
  color: #FFFFFF;
  font-family: "quicksand_medium";
  font-size: 25px;
  font-weight: 500;
  line-height: 1.3em;
}
.heading {
  color: var( --green-color );
  font-family: "quicksand_bold", Sans-serif;
  font-size: 43px;
  font-weight: 800;
}
</style>
