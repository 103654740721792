<template>
  <div class="row m-0">
    <div class="col-12 p-0">
      <div class="container-lg" >
        <PageTitle :title="$route.name" />
        <div class="row mx-0 justify-content-center page_top">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center">
              <div class="col-11 col-sm-9 col-md-7 col-lg-6 col-xl-5 col-xxl-4 text-center p-0 my-4 page_heading">
                Become an ambassador<br/>of change today
              </div>
            </div>
            <form @submit.prevent class="w-100 h-100">
            <div class="row m-0 justify-content-center">
              <div class="col-11 col-sm-10 col-md-8 col-lg-7 col-xl-7 col-xxl-6 p-0 my-4 card_item">
                <div class="row mx-0 h-100 justify-content-center">
                  <div class="col-12 p-0">
                    <ContentWithBackground>
                      <div class="row m-0 justify-content-center">
                        <div class="col-12 text-center p-0 my-4">
                          <div class="heading">
                            Join now
                          </div>
                        </div>
                        <div class="col-12 p-0">
                          <div class="row m-0 justify-content-center">
                            <div class="col-12 mb-3">
                              <SelectAll
                                v-model="v$.userTitleId.$model"
                                id="title"
                                label="Title: *"
                                :options="titles"
                                placeholder="Title"
                                displayValue="userTitleDescription"
                                saveValue="userTitleID"
                                color="gold"
                                :errors="v$.userTitleId.$errors"
                                :hasErrors="v$.userTitleId.$errors.length"
                                :isSearch="true"
                                width="full"
                              />
                            </div>
                            <div class="col-6 mb-3">
                              <TextField type="text"  label="First Name: *" placeholder="First Name" v-model="firstName" id="firstName" color="gold" :errors="v$.firstName.$errors" :hasErrors="v$.firstName.$errors.length" />
                            </div>
                            <div class="col-6 mb-3">
                              <TextField type="text"  label="Last Name: *" placeholder="Last Name" v-model="lastName" id="lastName" color="gold" :errors="v$.lastName.$errors" :hasErrors="v$.lastName.$errors.length" />
                            </div>
                            <div class="col-6 mb-3">
                              <TextField type="email"  label="Email Address: *" placeholder="Email Address:" v-model="username" id="username" color="gold" autocomplete="username" :errors="v$.username.$errors" :hasErrors="v$.username.$errors.length" />
                            </div>
                              <div class="col-6 mb-3">
                              <TextField type="text"  color="gold" placeholder="Mobile/Tel" label="Mobile/Tel: *" v-model="cell" id="mobile" :errors="v$.cell.$errors" :hasErrors="v$.cell.$errors.length" />
                            </div>
                            <div class="col-6 mb-4" v-if="!user">
                              <TextField type="password" label="Password: *" placeholder="Password" v-model="password" id="password" color="gold" autocomplete="new-password" :errors="v$.password.$errors" :hasErrors="v$.password.$errors.length" />
                            </div>
                            <div class="col-6 mb-4" v-if="!user">
                              <TextField type="password" label="Confirm Password: *" placeholder="Confirm Password" v-model="confirmPassword" id="confirmPassword" color="gold" autocomplete="confirm-password" :errors="confirmErrorText" :hasErrors="confirmErrors" @input="checkPasswordsMatch" />
                            </div>
                            <div class="col-12 mb-3 mt-4" v-if="!user">
                              <MapAutocomplete
                                @inputChanged="updateAddress"
                                placeholder="Search Address"
                                id="address"
                                :value="primaryAddress"
                                :map="true"
                                color="outline-green"
                              />
                            </div>
                            <div class="col-12 mb-3">
                              <TextField type="text"  label="Address Line 1: *" placeholder="Address Line 1" v-model="primaryAddress.addressLine1" id="addressLine1" color="gold" :errors="v$.primaryAddress.addressLine1.$errors" :hasErrors="v$.primaryAddress.addressLine1.$errors.length" />
                            </div>
                            <div class="col-12 mb-3">
                              <TextField type="text"  label="Address Line 2:" placeholder="Address Line 2" v-model="primaryAddress.addressLine2" id="addressLine2" color="gold" />
                            </div>
                            <div class="col-6 mb-3">
                              <TextField type="text"  label="Town/City: *" placeholder="Town/City" v-model="primaryAddress.town" id="town" color="gold" :errors="v$.primaryAddress.town.$errors" :hasErrors="v$.primaryAddress.town.$errors.length" />
                            </div>
                            <div class="col-6 mb-3">
                              <TextField type="text"  label="Province:" placeholder="Province" v-model="primaryAddress.province" id="province" color="gold" />
                            </div>
                            <div class="col-6 mb-3">
                              <TextField type="text"  label="Postal Code:" placeholder="Postal Code" v-model="primaryAddress.postalCode" id="postalCode" color="gold" />
                            </div>
                            <div class="col-6 mb-3">
                              <SelectAll
                                v-model="primaryAddress.country"
                                id="country"
                                :options="countries"
                                placeholder="Country"
                                displayValue="description"
                                color="gold"
                                label="Country:"
                                :isSearch="true"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-6 my-4" v-if="isUK">
                          <label for="dbsClosure" class="form-label medium">DBS Closure</label>
                          <input class="form-contol gold-text bold" type="file" id="dbsClosure" @change="handleDBSUpload">
                        </div>
                        <div class="col-6 my-4" v-if="isUK">
                          <label for="docs" class="form-label medium">Supporting Documentation</label>
                          <input class="form-contol gold-text bold" type="file" id="docs" multiple @change="handleSupportingFilesUpload">
                        </div>
                        <div class="col-12 mb-3">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="consent" v-model="correspondence" :class="{ 'error' : v$.correspondence.$errors.length }" >
                            <label class="form-check-label" for="flexCheckDefault">
                              I am happy to receive updates from Al-Imdaad Foundation by email, phone and SMS, and may opt-out
                            </label>
                          </div>
                        </div>
                      </div>
                    </ContentWithBackground>
                  </div>
                  <div class="col-12 p-4 bg text-center">
                    <Button color="gold" :btnText="sendingStatus" icon="arrow" @click="checkErrors" :disabled="sendingStatus === 'Success' || sendingStatus === 'Sending' || (user && user.volunteer) || status === 'You are already a volunteer'" :isLoading="sendingStatus === 'Sending'">
                      <IconArrowForward color="black" />
                    </Button>
                    <div class="text-center">
                      {{status}}
                    </div>
                    <div class="text-center" v-if="user && user.volunteer">
                      You are already a volunteer
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </form>
          </div>
        </div>
      </div>
      <LatestAppealsWithSlider />
    </div>
    <Popup v-if="sendingStatus === 'Success'" @close="closeReset">
      <div class="row mt-4 justify-content-center text-center">
        <div class="col-7 col-md-6 text-center">
          <img src="@/components/animations/tickAnimation.gif" v-if="isApp" class="img-fluid" />
          <TickAnimation v-else />
        </div>
        <div class="col-12 mb-1 text-center medium font23 green-text">
          Jazakallah Khair!
        </div>
        <div class="col-10 mb-3 bigger_font text-center medium green-text">
          Volunteer application submitted.
        </div>
        <div class="col-10 mb-3 text-center">
          We will be in touch soon...
        </div>
        <div class="col-10 text-center" v-if="user">
          <Button color="green" btnText="Add another volunteer" icon="arrow" @click="newForm" >
            <IconPlus />
          </Button>
        </div>
      </div>
    </Popup>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    LatestAppealsWithSlider: defineAsyncComponent(() => import('@/components/LatestAppealsWithSlider.vue')),
    MapAutocomplete: defineAsyncComponent(() => import('@/components/MapAutocomplete.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue')),
    SelectAll: defineAsyncComponent(() => import('@/components/SelectAll.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    IconPlus: defineAsyncComponent(() => import('@/components/icons/IconPlus.vue')),
    Popup: defineAsyncComponent(() => import('@/views/Popup.vue')),
    TickAnimation: defineAsyncComponent(() => import('@/components/animations/TickAnimation.vue')),
    ContentWithBackground: defineAsyncComponent(() => import('@/components/styling/ContentWithBackground.vue'))
  },
  name: 'Volunteer',
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  validations: {
    userTitleId: {
      required
    },
    firstName: {
      required
    },
    lastName: {
      required
    },
    password: {
      required,
      minLength: minLength(6)
    },
    username: {
      required,
      email
    },
    cell: {
      required
    },
    primaryAddress: {
      addressLine1: {
        required
      },
      town: {
        required
      }
    },
    correspondence: {
      checked: value => value === true
    }
  },
  data () {
    return {
      userTitleId: '',
      firstName: '',
      lastName: '',
      email: '',
      username: '',
      password: '',
      confirmPassword: '',
      cell: null,
      primaryAddress: {
        addressLine1: '',
        addressLine2: '',
        town: '',
        postalCode: '',
        country: {
          countryID: null,
          description: '',
          isoCountryCode2: ''
        },
        province: ''
      },
      dbsclosureFile: {
        fileName: '',
        base64: '',
        contentType: ''
      },
      supportingFiles: [],
      correspondence: false,
      confirmErrors: 0,
      confirmErrorText: [],
      status: '',
      sendingStatus: 'Join Now'
    }
  },
  mounted () {
    this.fetchTitles()
    this.fetchCorrespondence()
    if (this.user) {
      this.setUser()
    } else {
    }
  },
  computed: {
    ...mapGetters([
      'titles',
      'countries',
      'correspondenceOptions',
      'user',
      'jwt',
      'isApp'
    ]),
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    user () {
      if (this.user) {
        this.setUser()
      }
    }
  },
  methods: {
    ...mapActions([
      'fetchTitles',
      'fetchCorrespondence',
      'volunteer'
    ]),
    closeReset () {
      this.$router.push('/')
    },
    newForm () {
      this.userTitleId = ''
      this.firstName = ''
      this.lastName = ''
      this.email = ''
      this.username = ''
      this.password = ''
      this.confirmPassword = ''
      this.cell = null
      this.primaryAddress.addressLine1 = ''
      this.primaryAddress.addressLine2 = ''
      this.primaryAddress.town = ''
      this.primaryAddress.postalCode = ''
      this.primaryAddress.country.countryID = null
      this.primaryAddress.country.description = ''
      this.primaryAddress.country.isoCountryCode2 = ''
      this.primaryAddress.province = ''
      this.dbsclosureFile.fileName = ''
      this.dbsclosureFile.base64 = ''
      this.dbsclosureFile.contentType = ''
      this.supportingFiles = []
      this.correspondence = false
      this.confirmErrors = 0
      this.confirmErrorText = []
      this.status = ''
      this.sendingStatus = 'Join Now'
    },
    setUser () {
      this.userTitleId = this.user.userTitleID
      this.firstName = this.user.firstName
      this.lastName = this.user.lastName
      this.email = this.user.email
      this.username = this.user.email
      this.cell = this.user.cell
      this.primaryAddress.addressLine1 = this.user.postalAddress1
      this.primaryAddress.addressLine2 = this.user.postalAddress2
      this.primaryAddress.town = this.user.town
      this.primaryAddress.postalCode = this.user.postalcode
      if (this.user.country) {
        this.primaryAddress.country.countryID = this.user.country.countryID
        this.primaryAddress.country.description = this.user.country.description
        this.primaryAddress.country.isoCountryCode2 = this.user.country.isoCountryCode2
      }
      this.primaryAddress.province = this.user.province
    },
    async saveVolunteer (val) {
      this.volunteer(val)
    },
    async handleDBSUpload (e) {
      const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
      const file = e.target.files[0]
      this.dbsclosureFile.fileName = file.name
      this.dbsclosureFile.contentType = file.type
      const base64 = await toBase64(file)
      this.dbsclosureFile.base64 = base64.replace(/^[^,]+, */, '')
    },
    async handleSupportingFilesUpload (e) {
      e.target.files.forEach(file => {
        this.base64Array(file)
      })
    },
    async base64Array (theFile) {
      const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
      const base64 = await toBase64(theFile)
      const saveBase64 = base64.replace(/^[^,]+, */, '')
      const object = {
        fileName: theFile.name,
        base64: saveBase64,
        contentType: theFile.type
      }
      this.supportingFiles.push(object)
    },
    updateAddress (val) {
      const country = this.countries.find(item => item.description === val.country)
      const address = {
        addressLine1: `${val.street_number} ${val.route}`,
        addressLine2: val.sublocality_level_1,
        town: val.locality,
        postalCode: val.postal_code,
        country: country,
        province: val.province
      }
      this.primaryAddress = address
    },
    checkPasswordsMatch () {
      if (this.password !== this.confirmPassword) {
        this.confirmErrors = 1
        this.confirmErrorText = ['Passwords do not match']
      } else {
        this.confirmErrors = 0
        this.confirmErrorText = []
      }
    },
    async checkErrors () {
      if (!this.user) {
        if (this.password !== this.confirmPassword) {
          this.confirmErrors = 1
          this.confirmErrorText = ['Passwords do not match']
          return
        }
        this.v$.$touch()
        if (this.v$.firstName.$invalid || this.v$.lastName.$invalid || this.v$.username.$invalid || this.v$.password.$invalid || this.v$.primaryAddress.addressLine1.$invalid || this.v$.primaryAddress.town.$invalid || this.v$.cell.$invalid) {
          this.confirmErrors = 1
          this.status = 'Error. Please check your details'
          return
        }
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) return
        const details = {
          userRegistrationDto: {
            userTitleId: this.userTitleId,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.username,
            username: this.username,
            credentials: {
              password: this.password,
              confirmPassword: this.confirmPassword
            },
            cell: this.cell,
            primaryAddress: {
              primaryAddress: true,
              addressType: 'PHYSICAL_ADDRESS',
              addressLine1: this.primaryAddress.addressLine1,
              addressLine2: this.primaryAddress.addressLine2,
              town: this.primaryAddress.town,
              postalCode: this.primaryAddress.postalCode,
              houseNumberName: 'number',
              id: {}
            },
            correspondence: this.correspondenceOptions
          },
          supportingFiles: this.supportingFiles,
          dbsclosureFile: this.dbsclosureFile
        }
        this.sendingStatus = 'Sending'
        const ret = await this.volunteer(details)
        if (ret) {
          this.status = ret
          this.sendingStatus = 'Join Now'
        } else {
          this.sendingStatus = 'Success'
        }
      } else {
        const details = {
          userID: this.user.userID,
          supportingFiles: this.supportingFiles,
          dbsclosureFile: this.dbsclosureFile
        }
        this.sendingStatus = 'Sending'
        await this.volunteer(details)
        this.sendingStatus = 'Success'
      }
      return true
      // this.registerDonor(user)
      // actually submit form
    }
  }
}
</script>

<style scoped>
.card_item {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 5px 5px 15px 0px rgb(0 0 0 / 9%);
  box-shadow: rgba(0, 0, 0, 0.09) 5px 5px 10px 0px;
}
.heading {
  color: var(--green-color-dark);
  font-family: "quicksand_bold", Sans-serif;
  font-size: 2.2rem;
  font-weight: 600;
}
.card_title {
  font-family: "Quicksand", Sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--green-color);
}
.card_heading {
  font-family: "Quicksand", Sans-serif;
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--green-color-dark);
}
.bg {
  background-color: var(--green-color-dark);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 29px;
  font-weight: 500;
  line-height: 1.3em;
  letter-spacing: 8px;
}
.form-check-input.error {
  border: 2px solid red;
}
@media (min-width: 992px) {
  .card_title {
    font-size: 1.5rem;
    color: var(--green-color);
  }
}
</style>
