<template>
  <div class="row m-0 holder h-100">
    <div class="bg_holder">
      <div class="background"></div>
    </div>
    <div class="col-12 p-4 above ">
      <div class="row m-0 h-100 justify-content-center align-items-cener">
        <div class="col-12 text-center">
          <slot></slot>
          <div class="infoText mt-2">
            {{ leftText }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'WaysBoxLeftBgComponent',
  props: ['icon', 'leftText']
}
</script>

<style scoped>
.holder {
  position: relative;
}
.bg_holder {
  position: absolute;
  left: 0;
  top: 0;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  background-image: linear-gradient(rgba(244, 244, 244, 0.96) 0%, rgb(244, 244, 244) 100%);
  max-width: 100%;
  width: 100%;
  height: 100%;
  padding: 0;
}
.background {
  background-image: url(../../../../assets/images/bg.png);
  position: absolute;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  background-size: 50%;
  opacity: 0.02;
  height: 100%;
  width: 100%;
}
.above {
  z-index: 1;
  color: var(--green-color-dark);
}
.infoText {
  color: #54595F;
  font-family: "quicksand", Sans-serif;
  font-size: 15px;
  font-weight: 400;
}

</style>
