<template>
  <div class="row m-0">
    <div class="col-12 p-0">
      <div class="container-lg" >
        <PageTitle :title="$route.name" />
        <div class="row mx-0 justify-content-center page_top">
          <div class="col-12 p-0">
            <ContactForm />
            <BasicContactInfo />
            <QuickContactLinks />
          </div>
        </div>
      </div>
      <MainContactInfo />
      <DonationPoints v-if="!isUK" id="donationPoints" />
      <LatestAppealsWithSlider />
    </div>
  </div>
</template>

<script>

import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    LatestAppealsWithSlider: defineAsyncComponent(() => import('@/components/LatestAppealsWithSlider.vue')),
    ContactForm: defineAsyncComponent(() => import('./views/contactform/ContactForm.vue')),
    BasicContactInfo: defineAsyncComponent(() => import('./views/basiccontactinfo/BasicContactInfo.vue')),
    QuickContactLinks: defineAsyncComponent(() => import('./views/quickcontactlinks/QuickContactLinks.vue')),
    MainContactInfo: defineAsyncComponent(() => import('./views/maincontactinfo/MainContactInfo.vue')),
    DonationPoints: defineAsyncComponent(() => import('./views/donationpoints/DonationPoints.vue'))
  },
  name: 'Contact',
  watch: {
    $route () {
      if (this.$route.hash) {
        this.scrollToPoints()
      }
    }
  },
  async mounted () {
    this.scrollToPoints()
  },
  methods: {
    scrollToPoints () {
      this.$nextTick(() => {
        if (this.$route.hash) {
          const el = document.querySelector(this.$route.hash)
          setTimeout(() => {
            el && el.scrollIntoView()
          }, 700)
        }
      })
    }
  },
  computed: {
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
