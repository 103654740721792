<template>
  <div class="container-lg" >
    <div class="row mx-0">
      <div class="col-12 p-0">
        <PageTitle :title="$route.name" />
        <div class="row mx-0 justify-content-center page_top">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center">
              <div class="col-11 col-md-8 col-lg-7 col-xl-6 text-center p-0 my-4 page_heading">
                Search for a verified fundraiser near you...
              </div>
            </div>
            <div class="row m-0 justify-content-center mb-5">
              <div class="col-12 col-sm-10 col-md-7 col-lg-7 col-xl-6 text-center">
                We maintain a system of verified fundraisers to protect your donations from being mishandled in our name. Please check the list below before donating to anyone claiming to raise funds for us.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FundraisersSection />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    FundraisersSection: defineAsyncComponent(() => import('./views/FundraisersSection.vue'))
  },
  name: 'VerifiedFundraisers'
}
</script>
