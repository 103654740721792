<template>
  <div class="container-lg" >
    <div class="row m-0">
      <div class="col-12 p-0">
        <PageTitle :title="$route.name" />
        <div class="row mx-0 justify-content-center page_top">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center">
              <div class="col-11 col-md-8 col-lg-7 col-xl-6 text-center p-0 my-4 page_heading">
                Striving to serve humanity for over nineteen years...
              </div>
            </div>
            <div class="row m-0 justify-content-center">
              <div class="col-11 col-sm-9 col-md-7 col-lg-6 col-xl-5 text-center p-0 my-4 page_heading">
                <YouTubeVideo link="xqUxOLESpWQ"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-0 justify-content-center about_content" v-if="isUK">
      <div class="col-12 col-md-9 col-lg-8 col-xl-7 text-center">
        <div class="row mx-0 justify-content-center my-5">
          <div class="col-12 p-0 my-4 heading">
            Our Mission
          </div>
          <div class="col-12 p-0 bold">
            The Al-Imdaad Foundation is a non-profit humanitarian aid relief organisation (NGO) registered in the Republic of South Africa, dedicated to providing humanitarian services in crisis and non-crisis situations to the neediest orphans, widows and destitute families, irrespective of race, religion, culture, creed, and geographical&nbsp;boundary.
          </div>
        </div>
        <div class="row mx-0 justify-content-center my-5">
          <div class="col-12 p-0 my-4 heading">
            Our Vision
          </div>
          <div class="col-12 p-0 bold">
            The Al-Imdaad Foundation aspires to excel in humanitarian relief, providing humanitarian services with the highest level of compassion, professionalism, and care under the supervision of a team of professionals from South Africa and&nbsp;abroad.
          </div>
        </div>
        <div class="row mx-0 justify-content-center my-5">
          <div class="col-12 p-0 my-4 heading">
            How we're unique:
          </div>
          <div class="col-12 p-0 bold mb-3">
            The Al-Imdaad Foundation was formed in South Africa by a minority community. The diverse cultural and linguistic context of South Africa has allowed the Foundation to take on a truly humanitarian perspective by striving to serve all&nbsp;communities.
          </div>
          <div class="col-12 p-0 bold mb-3">
            The Al-Imdaad Foundation is inspired by the humanitarian ethics of the Islamic tradition. We have always involved Islamic Scholars (Ulema) in our activities from early formation until current times. This ensures that all the organisation's operations are in accordance with Islamic guidance and&nbsp;principles.
          </div>
          <div class="col-12 p-0 bold">
            The Foundation responds quickly and effectively through country offices and links on the ground in countries across the world. We have a 100% donations policy so all donations to a specific cause or location go to that project and are not used for admin costs or implementation&nbsp;expenses.
          </div>
        </div>

        <div class="row mx-0 justify-content-center my-5">
          <div class="col-12 p-0 my-4 heading">
            Where we are:
          </div>
          <div class="col-12 p-0 bold mb-3">
            Country Offices: The Al-Imdaad Foundation has country offices in South Africa (Head Office), the United Kingdom, Jordan, Indonesia, Bangladesh, Chile, Kenya, the DR Congo, and Turkey. Provincial Offices in South Africa: The Foundation also has offices in the Western, Eastern and Northern Cape Provinces, the KwaZulu-Natal Province, and the Gauteng Province. These offices allow us to respond across South&nbsp;Africa.
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-0 justify-content-center about_content" v-else>
      <div class="col-12 col-md-9 col-lg-8 col-xl-7 text-center">
        <div class="row mx-0 justify-content-center my-5">
          <!-- <div class="col-12 p-0 my-4 heading">
            Our Mission
          </div> -->
          <div class="col-12 p-0 bold">
            Natural or man-made disasters, poverty and other challenges are a fact of life for many people in various parts of the world today. South Africans in general are very fortunate to be living in a country that is not only peaceful but is also developed in diverse fields. However, whilst enjoying the blessings of this prosperity, South Africans need to also reach out and help others who may not be so fortunate, whether they be within or outside of South Africa's&nbsp;borders.
          </div>
        </div>
        <div class="row mx-0 justify-content-center my-5">
          <div class="col-12 p-0 my-4 heading">
            Mission
          </div>
          <div class="col-12 p-0 bold">
            The Al-Imdaad Foundation is a non-profit humanitarian aid relief organisation (NGO) registered in the Republic of South Africa, dedicated to providing humanitarian services in crisis and non-crisis situations to most needy orphans, widows and destitute, irrespective of race, religion, culture, creed and geographical&nbsp;boundary.
          </div>
        </div>
        <div class="row mx-0 justify-content-center my-5">
          <div class="col-12 p-0 my-4 heading">
            Vision
          </div>
          <div class="col-12 p-0 bold">
            The Al-Imdaad Foundation aspires to excel in humanitarian relief, providing humanitarian services with the highest level of compassion, professionalism and care under the supervision of a team of professionals from South Africa and&nbsp;abroad.
          </div>
        </div>
        <div class="row mx-0 justify-content-center my-5">
          <div class="col-12 p-0 my-4 heading">
            Our Team
          </div>
          <div class="col-12 p-0 bold">
            All trustees and personnel of the Al-Imdaad Foundation strive to serve humanity with the skills and expertise that they are blessed with. The team comprises of Theologians, Academics, Accountants, IT Specialists, Medical Personnel and Paramedics, Attorneys, Businessmen and other voluntary aid&nbsp;workers.
          </div>
        </div>
        <div class="row mx-0 justify-content-center my-5">
          <div class="col-12 p-0 my-4 heading">
            Friends of the Suffering
          </div>
          <div class="col-12 p-0 bold">
            Al-Imdaad Foundation (South Africa), a sister organisation and more commonly known as "Friends of the Suffering" has been registered purely to render humanitarian services in South Africa. This relief organisation has been registered with the <a href="https://www.sars.gov.za/" target="_blank">South African Revenue Service</a> under Section 18a for tax exemption benefits to South African donors and is a Public&nbsp;Benefit&nbsp;Organisation&nbsp;(PBO).
          </div>
        </div>

        <div class="row mx-0 justify-content-center my-5">
          <div class="col-12 p-0 my-4 heading">
            IFRC - Geneva, Switzerland
          </div>
          <div class="col-12 p-0 bold">
            The <a href="https://www.ifrc.org/code-conduct-signatories?webform_submission_value=&webform_submission_value_1=&page=1" target="_blank">Al-Imdaad Foundation</a> is a signatory to the <a href="https://www.ifrc.org/document/code-conduct-international-red-cross-and-red-crescent-movement-and-ngos-disaster-relief" target="_blank">Code of Conduct</a> for the International Red Cross and Red Crescent Movements and NGO's in Disaster Relief. The Foundation supports the Code of Conduct and strives to incorporate its principles in its humanitarian&nbsp;work.
          </div>
        </div>
        <div class="row mx-0 justify-content-center my-5">
          <div class="col-12 p-0 my-4 heading">
            Proudly South African
          </div>
          <div class="col-12 p-0 bold">
            The Al-Imdaad Foundation has registered with <a href="https://www.proudlysa.co.za/" target="_blank">Proudly South African</a> and is committed to portray its activities, both locally and abroad in the name of the people of South Africa. Various meetings with representatives of the Department of International Relations and Cooperation and South African Embassies in the countries of our activities have taken place to enhance our commitment to a proudly South African effort, thereby ensuring South Africa's place in the International community of humanitarian aid&nbsp;workers.
          </div>
        </div>
        <div class="row mx-0 justify-content-center my-5">
          <div class="col-12 p-0 my-4 heading">
            International Offices
          </div>
          <div class="col-12 p-0 bold">
            The Al Imdaad Foundation has registered offices in Indonesia, Jordan, Australia, the United Kingdom, Bangladesh, Chile and Kenya. These offices are established to take care of our various projects in these regions and to act as fund raising partners. The foundation is at an advanced stage to establish further representative offices in other parts of the world (including Turkey). The South African <a href="http://www.dirco.gov.za/" target="_blank">Department of International Relations and Cooperation</a> and its various embassies are kept informed of our activities&nbsp;globally.
          </div>
        </div>
        <div class="row mx-0 justify-content-center my-5">
          <div class="col-12 p-0 my-4 heading">
            Abridged Audited Financial Statements
          </div>
          <div class="col-12 p-0 bold">
            Please click <a href="https://alimdaad.com/static/upload/documents2017/Al-Imdaad%20-%20AFS%202017.pdf" target="_blank">here</a> to view our Annual Abridged Audited Financial&nbsp;Statements.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    YouTubeVideo: defineAsyncComponent(() => import('@/components/YouTubeVideo.vue'))
  },
  name: 'About Us',
  computed: {
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.heading {
  color: var( --green-color );
  font-family: "quicksand_bold", Sans-serif;
  font-size: 3rem;
  font-weight: 800;
}
.about_content {
  font-size: 1.3rem;
}
a, a:visited {
  text-decoration: underline;
  color: var(--green-color-light);
}
a:hover {
  color: var(--green-color-lighter);
}
</style>
