<template>
  <div class="row m-0">
    <div class="col-12 p-0">
      <div class="container-lg" >
        <PageTitle :title="$route.name" />
        <div class="row mx-0 justify-content-center page_top">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center">
              <div class="col-11 col-sm-10 col-md-8 col-lg-7 col-xl-7 col-xxl-6 p-0 my-4 card_item">
                <div class="row mx-0 h-100 justify-content-center">
                  <div class="col-12 p-0">
                    <ContentWithBackground class="rounded">
                      <div class="row m-0 justify-content-center">
                        <div class="col-10 text-center mb-4">
                          <div class="heading">
                            Tax Returns Benefits when donating to the Al-Imdaad Foundation
                          </div>
                        </div>
                        <div class="col-11 mb-3">
                          The South African Revenue Services (SARS) allows individuals, Close Corporations, Trusts, Private and Public Companies or any other Tax Paying entity to donate up to 10% of their taxable income to charities that are registered as Public Benefit Organisations (PBOs). The amount donated can be deducted from the taxable income of any taxpayer. This concession allows you to personally put your money where you think the government should.
                        </div>
                        <div class="col-11 mb-3">
                          The Al-Imdaad Foundation (South Africa), also known as the Friends of the Suffering, is registered as a Public Benefit Organisation (PBO) with the South African Revenue Service under Section 18A, which provides tax exemption benefits to South African donors. For you to take advantage of this Tax Benefit, call our Finance Department on 0861786243, and we will be glad to assist you. We would require the full name, address, contact telephone number/s and the Income Tax Reference Number of the individual or entity making the donation, and we would gladly issue you with a TAX CERTIFICATE, which you may submit to your auditors for a tax rebate. Please ensure to email details of your deposit to 18a@alimdaad.com to request your Tax Certificate.
                        </div>
                        <div class="col-11 mb-3">
                          Details of Section 18A tax benefit account details can be found below. Please note that these benefits apply to South African donors only.
                        </div>
                        <div class="col-11">
                          <div><b>Bank Details</b></div>
                          <div>First National Bank</div>
                          <div>Account name: FRIENDS OF THE SUFFERING</div>
                          <div>Branch name: ESTCOURT</div>
                          <div>Branch code: 220325</div>
                          <div>Account number: 62102362150</div>
                          <div>Swift code: FIRNZAJJ</div>
                          <div>Reference: Name/Company Name + Zakaat/Lillah/Sadaqah/Etc</div>
                        </div>
                      </div>
                    </ContentWithBackground>
                  </div>
                </div>
              </div>
            </div>
            <div class="row m-0 justify-content-center">
              <div class="col-auto">
                <Button color="green" btnText="DONATE NOW" @buttonClicked="$router.push('/appeals/1')" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <LatestAppealsWithSlider />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    ContentWithBackground: defineAsyncComponent(() => import('@/components/styling/ContentWithBackground.vue')),
    LatestAppealsWithSlider: defineAsyncComponent(() => import('@/components/LatestAppealsWithSlider.vue'))
  },
  name: 'Section 18A'
}
</script>

<style scoped>
.card_item {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 5px 5px 15px 0px rgb(0 0 0 / 9%);
  box-shadow: rgba(0, 0, 0, 0.09) 5px 5px 10px 0px;
}
.heading {
  color: var(--green-color-dark);
  font-family: "quicksand_bold", Sans-serif;
  font-size: 2.2rem;
  font-weight: 600;
}

</style>
