<template>
  <div class="row m-0 card_item mb-3">
    <div class="col-lg-5 p-0">
      <WaysBoxLeftBgComponent :leftText="leftText">
        <IconRound size="xbig" align="center">
          <slot name="icon"></slot>
        </IconRound>
      </WaysBoxLeftBgComponent>
    </div>
    <div class="col-lg-7 p-4">
      <div class="row">
        <div class="col-12 smallheading">
          {{title}}
        </div>
        <slot name="main"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import IconRound from '@/components/IconRound.vue'
import WaysBoxLeftBgComponent from './WaysBoxLeftBgComponent.vue'

export default {
  name: 'WaysBoxComponent',
  props: ['title', 'leftText'],
  components: { WaysBoxLeftBgComponent, IconRound }
}
</script>

<style scoped>
.img_height {
  max-height: 24px;
}
.card_item {
  background-color: #fff;
  color: #7a7a7a;
  border-radius: 13px;
  box-shadow: rgba(0, 0, 0, 0.09) 5px 5px 10px 0px;
}
.smallheading {
  color: var( --green-color );
  font-family: "quicksand_bold", Sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
}
.title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 29px;
  font-weight: 500;
  line-height: 1.3em;
  letter-spacing: 8px;
}
.col-auto {
  max-width: 91.6666666667%;
}
.dark {
  color: #54595f;
  line-height: 24px;
}
@media (min-width: 992px) {
  .smallheading {
    font-size: 1.7rem;
    text-align: left;
  }
}
</style>
