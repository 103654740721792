<template>
  <div class="container-lg" >
    <div class="row m-0">
      <div class="col-12 p-0">
        <PageTitle :title="$route.name" />
        <div class="row mx-0 justify-content-center page_top">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center mb-5">
              <div class="col-11 col-md-8 col-lg-7 col-xl-6 text-center p-0 my-5 page_heading">
                Serve humanity on-the-go with our official app.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-0 justify-content-center">
      <div class="col-12 col-md-10 col-lg-9 col-xl-8">
        <div class="row justify-content-center my-5">
          <div class="col-12 col-lg-7">
            <div class="row mx-0 align-items-center mb-4">
              <div class="col-auto p-0">
                <img src="@/assets/images/appstore.svg" class="img-fluid appStore cursor" @click="goAppStore" />
              </div>
              <div class="col-auto p-0">
                <img src="@/assets/images/googleplay.png" class="img-fluid playStore cursor" @click="goPlayStore" />
              </div>
            </div>
            Have you ever wished that it was easier to make a charitable donation, to calculate and pay your annual Zakaat or to give that quick Sadaqah before last-minute travel or writing an exam? At the click of a button the Al-Imdaad Foundation App allows you to donate online safely and securely, to track your donations, view donation history and browse our currently running projects. The App is easy to navigate, smart and practical and comes with the following key features:
            <ul class="mt-4">
              <li class="bold mb-3">Calculate and pay your Zakaat instantly with the built in Zakaat Calculator</li>
              <li class="bold mb-3">Quick and easy Sadaqah on the go- for travel, easing difficulties or any other intentions</li>
              <li class="bold mb-3">Directly donate to relief projects globally payment options to suit your need</li>
              <li class="bold mb-3">Easy viewing of the latest projects and appeals as well as project recommendations to suit you</li>
              <li class="bold mb-3">Managing your Al-Imdaad Foundation Donor Profile</li>
              <li class="bold mb-3">Viewing your past donations</li>
              <li>AND MORE...</li>
            </ul>
          </div>
          <div class="col-9 col-sm-6 col-md-5 text-center order-first order-lg-last mb-4 mb-md-0">
            <img src="@/assets/images/appdownload.png" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue'))
  },
  name: 'App Download',
  methods: {
    goPlayStore () {
      window.open('https://play.google.com/store/apps/details?id=io.resourcedesign.aifapp', '_blank').focus()
    },
    goAppStore () {
      window.open('https://apps.apple.com/za/app/al-imdaad-foundation/id1507938238', '_blank').focus()
    }
  }
}
</script>

<style scoped>
.playStore {
  height: 52.09px;
}
.appStore {
  height: 35px;
}
@media (min-width: 576px) {
  .playStore {
    height: 77px;
  }
  .appStore {
    height: 51.74px;
  }
}

</style>
