<template>
  <div class="container-lg" >
    <div class="row m-0">
      <div class="col-12 p-0">
        <PageTitle :title="$route.name" />
        <TheProcessSteps />
      </div>
      <div class="col-12">
        <div class="row  justify-content-center">
          <div class="col-11 col-md-6 mb-3">
            <TextField type="text" color="gold" placeholder="Person / Organisation" label="Zakaah Calculation for:" v-model="zakaah.name" id="zakaahName" />
          </div>
        </div>
        <SetDate />
      </div>
      <!-- ASSET / LIABILITY BUTTONS -->
      <div class="col-12">
        <div class="row my-3" v-if="zakaah.name !== ''">
          <div class="col-12 col-md-6 mb-3">
            <Button color="green" btnText="Assets" icon="arrow" @buttonClicked="displayAssets" class="w-100">
              <IconArrowForward color="gold" size="size20" />
            </Button>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <Button color="green" btnText="Liabilities" icon="arrow" @buttonClicked="displayLiabilities" class="w-100">
              <IconArrowForward color="gold" size="size20" />
            </Button>
          </div>
        </div>
      </div>
      <!-- END ASSET / LIABILITY BUTTONS -->
      <PreviousZakaah :zakaahName="zakaah.name" :zakaahDate="zakaah.date" />
      <!-- ASSETS -->
      <div class="col-12 col-md">
        <div class="row m-0">
          <div class="col-12" v-if="zakaatAssetsTotal > 0">
            <div class="row align-items-center justify-content-between">
              <div class="col-auto heading">
                Assets
              </div>
              <div class="col-auto smallheading text-nowrap">
                {{defaultCurrency}}{{totalAssetsDisplay}}
              </div>
            </div>
            <div class="row m-0">
              <div class="col-12">
                <div class="row" v-if="currentZakaah.zakaatAssets.businessAssets.length > 0">
                  <div class="col-12 bold smallheading my-4">
                    Businesses
                  </div>
                </div>
                <div class="row py-2 border-bottom align-items-center" v-for="(asset, key) in currentZakaah.zakaatAssets.businessAssets" :key="key">
                  <div class="col-8">
                    <div class="row">
                      <div class="col-12 bold mb-1" v-if="asset.description">
                        {{asset.description}}
                      </div>
                      <div class="col-12 font12" v-if="asset.businessAssetType">
                        {{ displayDescription(asset.businessAssetType) }}
                      </div>
                    </div>
                  </div>
                  <div class="col text-end bold text-nowrap">
                    {{defaultCurrency}}{{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                  </div>
                </div>
                <div class="row" v-if="currentZakaah.zakaatAssets.commodities.length > 0">
                  <div class="col-12 bold smallheading my-4">
                    Commodities
                  </div>
                </div>
                <div class="row py-2 border-bottom align-items-center" v-for="(asset, key) in currentZakaah.zakaatAssets.commodities" :key="key">
                  <div class="col-8">
                    <div class="row">
                      <div class="col-12 bold mb-1" v-if="asset.description">
                        {{asset.description}}
                      </div>
                      <div class="col-12 font12" v-if="asset.commodityType">
                        {{displayDescription(asset.commodityType)}}<span class="ms-1" v-if="asset.commodityType === 'GOLD_JEWELLERY' || asset.commodityType === 'GOLD_KRUGERRAND_COIN'">{{ displayDescription(asset.proportion) }}</span>
                      </div>
                      <div class="col-12 font12" v-if="asset.commodityType">
                        {{ (asset.commodityType === 'GOLD_KRUGERRAND_COIN' ? `${asset.quantity} units` : `${asset.weightInGrams}g` ) }}
                      </div>
                    </div>
                  </div>
                  <div class="col text-end bold text-nowrap">
                    {{defaultCurrency}}{{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                  </div>
                </div>
                <div class="row" v-if="currentZakaah.zakaatAssets.debtorAssets.length > 0">
                  <div class="col-12 bold smallheading my-4">
                    Debtors
                  </div>
                </div>
                <div class="row py-2 border-bottom align-items-center" v-for="(asset, key) in currentZakaah.zakaatAssets.debtorAssets" :key="key">
                  <div class="col-8">
                    <div class="row">
                      <div class="col-12 bold mb-1" v-if="asset.description">
                        {{asset.description}}
                      </div>
                      <div class="col-12 font12" v-if="asset.debtorsAssetType">
                        {{ displayDescription(asset.debtorsAssetType) }}
                      </div>
                    </div>
                  </div>
                  <div class="col text-end bold text-nowrap">
                    {{defaultCurrency}}{{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                  </div>
                </div>
                <div class="row" v-if="currentZakaah.zakaatAssets.investmentAssets.length > 0">
                  <div class="col-12 bold smallheading my-4">
                    Investments
                  </div>
                </div>
                <div class="row py-2 border-bottom align-items-center" v-for="(asset, key) in currentZakaah.zakaatAssets.investmentAssets" :key="key">
                  <div class="col-8">
                    <div class="row">
                      <div class="col-12 bold mb-1" v-if="asset.description">
                        {{asset.description}}
                      </div>
                      <div class="col-12 font12" v-if="asset.investmentAssetType">
                        {{ displayDescription(asset.investmentAssetType) }}
                      </div>
                      <div class="col-12 font12" v-if="asset.amount">
                        <span v-if="asset.currency.symbol">{{asset.currency.symbol}}</span><span v-else>{{defaultCurrency}}</span> {{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                      </div>
                    </div>
                  </div>
                  <div class="col text-end bold text-nowrap">
                    {{defaultCurrency}}{{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                  </div>
                </div>
                <div class="row" v-if="currentZakaah.zakaatAssets.cashAssets.length > 0">
                  <div class="col-12 bold smallheading my-4">
                    Money
                  </div>
                </div>
                <div class="row py-2 border-bottom align-items-center" v-for="(asset, key) in currentZakaah.zakaatAssets.cashAssets" :key="key">
                  <div class="col-8">
                    <div class="row">
                      <div class="col-12 bold mb-1" v-if="asset.description">
                        {{asset.description}}
                      </div>
                      <div class="col-12 font12" v-if="asset.cashAssetType">
                        {{ displayDescription(asset.cashAssetType) }}
                      </div>
                      <div class="col-12 font12">
                        <span v-if="asset.currency.symbol">{{asset.currency.symbol}}</span><span v-else>{{defaultCurrency}}</span> {{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                      </div>
                    </div>
                  </div>
                  <div class="col text-end bold text-nowrap">
                    {{defaultCurrency}}{{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                  </div>
                </div>
                <div class="row" v-if="currentZakaah.zakaatAssets.partnershipAssets.length > 0">
                  <div class="col-12 bold smallheading my-4">
                    Partenrships
                  </div>
                </div>
                <div class="row py-2 border-bottom align-items-center" v-for="(asset, key) in currentZakaah.zakaatAssets.partnershipAssets" :key="key">
                  <div class="col-8">
                    <div class="row">
                      <div class="col-12 bold mb-1" v-if="asset.companyName">
                        {{asset.companyName}}
                      </div>
                      <div class="col-12 font12">
                        {{defaultCurrency}}{{parseFloat(Math.round((asset.netWorth) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }} | {{asset.percentageOwned}}%
                      </div>
                    </div>
                  </div>
                  <div class="col text-end bold text-nowrap">
                    {{defaultCurrency}}{{parseFloat(Math.round((asset.total) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                  </div>
                </div>
                <div class="row" v-if="currentZakaah.zakaatAssets.policyAssets.length > 0">
                  <div class="col-12 bold smallheading my-4">
                    Policies
                  </div>
                </div>
                <div class="row py-2 border-bottom align-items-center" v-for="(asset, key) in currentZakaah.zakaatAssets.policyAssets" :key="key">
                  <div class="col-8">
                    <div class="row">
                      <div class="col-12 bold mb-1" v-if="asset.description">
                        {{asset.description}}
                      </div>
                      <div class="col-12 font12" v-if="asset.policiesAssetType">
                        {{ displayDescription(asset.policiesAssetType) }}
                      </div>
                    </div>
                  </div>
                  <div class="col text-end bold text-nowrap">
                    {{defaultCurrency}}{{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END ASSETS -->
      <div class="col-auto d-none d-md-block py-4">
        <div class="divider"></div>
      </div>
      <!-- LIABILITIES -->
      <div class="col-12 col-md">
        <div class="row m-0">
          <div class="col-12" v-if="zakaatLiabilitiesTotal > 0">
            <div class="row align-items-center justify-content-between">
              <div class="col-auto heading">
                Liabilities
              </div>
              <div class="col-auto smallheading">
                {{defaultCurrency}}{{totalLiabilitiesDisplay}}
              </div>
            </div>
            <div class="row m-0">
              <div class="col-12">
                <div class="row" v-if="currentZakaah.zakaatLiabilities.basicLiabilities.length > 0">
                  <div class="col-12 bold smallheading my-4">
                    General Liabilities
                  </div>
                </div>
                <div class="row py-2 border-bottom align-items-center" v-for="(liability, key) in currentZakaah.zakaatLiabilities.basicLiabilities" :key="key">
                  <div class="col-8">
                    <div class="row">
                      <div class="col-12 bold mb-1">
                        {{liability.description}}
                      </div>
                      <div class="col-12 font12">
                        {{ liability.zakaatBasicLiabilityType }}
                      </div>
                    </div>
                  </div>
                  <div class="col text-end bold text-nowrap">
                    {{defaultCurrency}}{{parseFloat(Math.round((liability.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                  </div>
                </div>
                <div class="row" v-if="currentZakaah.zakaatLiabilities.zakaatHomeLoanLiabilities.length > 0">
                  <div class="col-12 bold smallheading my-4">
                    Home Loans
                  </div>
                </div>
                <div class="row py-2 border-bottom align-items-center" v-for="(liability, key) in currentZakaah.zakaatLiabilities.zakaatHomeLoanLiabilities" :key="key">
                  <div class="col-8">
                    <div class="row">
                      <div class="col-12 bold mb-1">
                        {{liability.description}}
                      </div>
                      <div class="col-12 font12">
                        {{ liability.creditProvider }}
                      </div>
                    </div>
                  </div>
                  <div class="col text-end bold text-nowrap">
                    {{defaultCurrency}}{{parseFloat(Math.round((liability.monthlyInstalments) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                  </div>
                </div>
                <div class="row" v-if="currentZakaah.zakaatLiabilities.zakaatVehicleLiabilities.length > 0">
                  <div class="col-12 bold smallheading my-4">
                    Vehicle Finance
                  </div>
                </div>
                <div class="row py-2 border-bottom align-items-center" v-for="(liability, key) in currentZakaah.zakaatLiabilities.zakaatVehicleLiabilities" :key="key">
                  <div class="col-8">
                    <div class="row">
                      <div class="col-12 bold mb-1">
                        {{liability.description}}
                      </div>
                      <div class="col-12 font12">
                        {{ liability.creditProvider }}
                      </div>
                    </div>
                  </div>
                  <div class="col text-end bold text-nowrap">
                    {{defaultCurrency}}{{parseFloat(Math.round((liability.monthlyInstalments) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END LIABILITIES -->

      <!-- ZAKAAH STUFF -->
      <div class="col-12 mt-4" v-if="total !== 0">
        <div class="row my-3">
          <div class="col-12 heading">
            Total Calculation
          </div>
        </div>
        <div class="row py-2 bold">
          <div class="col">
            Nisab Value
          </div>
          <div class="col text-right">
            {{defaultCurrency}}{{nisabDisplay}}
          </div>
        </div>
        <div class="row py-2 bold">
          <div class="col">
            Total Assets
          </div>
          <div class="col text-right">
            {{defaultCurrency}}{{totalAssetsDisplay}}
          </div>
        </div>
        <div class="row py-2 bold">
          <div class="col">
            Total Liabilities
          </div>
          <div class="col text-right">
            {{defaultCurrency}}{{totalLiabilitiesDisplay}}
          </div>
        </div>
        <div class="row py-2 bold">
          <div class="col">
            Zakaatable Amount
          </div>
          <div class="col text-right">
            {{defaultCurrency}}{{totalDisplay}}
          </div>
          <div class="col-12 medium disclaimer" v-if="totalPayable === 0">
            * NOTE: Zakaat payable is ZERO because Zakaatable amount is less than Nisaab threshold
          </div>
        </div>
        <div class="row py-2 smallheading">
          <div class="col">
            Total Payable
          </div>
          <div class="col text-right">
            {{defaultCurrency}}{{totalPayableDisplay}}
          </div>
        </div>
      </div>
      <!-- END ZAKAAH STUFF -->

      <!-- SAVE BUTTONS -->
      <div class="col-12 my-5" v-if="total !== 0">
        <div class="row">
          <div class="col-12 col-md-auto mb-3">
            <Button color="red" btnText="Reset" @buttonClicked="reset" />
          </div>
          <div class="col-12 col-md-auto mb-3">
            <Button color="gold" btnText="Login to save" @buttonClicked="isLogin = true" v-if="!user"></Button>
            <Button color="gold" :btnText="saveStatus" @buttonClicked="save" :disabled="saveStatus === 'Saving...' || saveStatus === 'Saved'" :icon="saveStatus === 'Saving...'" v-else :isLoading="saveStatus === 'Saving...'"></Button>
          </div>
          <div class="col-12 col-md-auto">
            <Button color="green" btnText="Donate Now" icon="arrow" @buttonClicked="donateStep" :isLoading="isDonating">
              <IconArrowForward color="gold"/>
            </Button>
          </div>
        </div>
      </div>
      <!-- END SAVE BUTTONS -->
    </div>
    <transition-group name="slide-side">
      <Assets
        v-if="showAssets"
        key="zakaatAssets"
        @close=closeSection
      />
      <Liabilities
        v-if="showLiabilities"
        key="zakaatLiabilities"
        @close=closeSection
      />
    </transition-group>
    <transition name="fade">
      <Alert title="Reset Zakaah" message="This operation will restart the whole Zakaah calculation process. Are you sure you want to continue?" v-if="showReset" @doAction1="resetAll" @close="showReset = false" @doAction2="showReset = false" key="alert" button1="Reset" button2="Cancel" ></Alert>
    </transition>
    <transition name="fade">
      <LoginPopup v-if="isLogin" @close="isLogin = false" @loggedIn="isLogin = false"/>
    </transition>
    <transition name="fade">
      <Popup v-if="isDonatePopup" @close="isDonatePopup = false" cols="col-xl-5 col-xxl-4">
        <div class="row mx-0 justify-content-center">
          <div class="col-12 text-center mb-3 green-text bold font19">
            Donate Zakaah
          </div>
          <div class="col-12 text-center letter-spacing font10 medium mb-2">
            CALCULATED ZAKAAH:
          </div>
          <div class="col-auto text-center mb-2" v-if="editTotal">
            <CurrencyField type="text" placeholder="0.00" id="amount" v-model="donation.amount" :currencySymbol="donation.currency" color="goldgold"  />
          </div>
          <div class="col-12 text-center mb-2" v-else>
            <Button color="green" size="big" :btnText="defaultCurrency + ' ' + totalPayableDisplay" />
          </div>
          <div class="col-12 text-center mb-3 font12">
            <div class="row justify-content-center align-itmes-center cursor" @click="editTotal = true">
              <div class="col-auto px-1">
                Edit Donation amount
              </div>
              <div class="col-auto px-0">
                <IconEdit size="size16" class="inline-icon" />
              </div>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="row mx-0 justify-content-center">
              <div class="col-12 text-center letter-spacing small medium mb-2">
                SELECT AN APPEAL:
              </div>
              <div class="col-10 col-md-10 col-lg-8 col-xl-7 p-0 text-center mb-4">
                <div class="row justify-content-center">
                  <div class="col-12 p-0 mb-3">
                    <SelectAll
                      v-model="selectedParentProject"
                      id="project"
                      :options="parentProjects"
                      placeholder="Projects"
                      displayValue="description"
                      color="white"
                      :isSearch="true"
                    />
                  </div>
                  <div class="col-12 p-0 mb-3" v-if="Object.keys(selectedParentProject).length !== 0 && childProjects.length > 0">
                    <SelectAll
                      v-model="selectedChildProject"
                      id="project"
                      :options="childProjects"
                      placeholder="Children Projects"
                      displayValue="description"
                      color="white"
                      :autoSelect="true"
                      :isSearch="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row justify-content-center">
              <div class="col-auto">
                <Button color="green" width="100" btnText="Added" icon="arrow" class="mb-1" :disabled="true" v-if="isAdded" />
                <Button color="green" width="100" btnText="Add to Cart" icon="arrow" @buttonClicked="addDonationToCart" class="mb-1" v-else :disabled="this.donation.amount <= 0">
                  <IconCart color="gold" size="size16" />
                </Button>
              </div>
              <div class="col-auto">
                <Button color="red" width="100" btnText="Donate Now" icon="arrow" @buttonClicked="donate" class="mb-1" :disabled="this.donation.amount <= 0">
                  <IconArrowForward color="white" size="size20" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    </transition>
    <transition name="comeIn">
      <AddCartPopup v-if="isAdded" @close="isAdded = false"/>
    </transition>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    CurrencyField: defineAsyncComponent(() => import('@/components/CurrencyField.vue')),
    Alert: defineAsyncComponent(() => import('./components/Alert.vue')),
    AddCartPopup: defineAsyncComponent(() => import('@/views/AddCartPopup.vue')),
    Assets: defineAsyncComponent(() => import('./views/zakaahassets/Assets.vue')),
    Liabilities: defineAsyncComponent(() => import('./views/zakaahliabilities/Liabilities.vue')),
    Popup: defineAsyncComponent(() => import('@/views/Popup.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    IconEdit: defineAsyncComponent(() => import('@/components/icons/IconEdit.vue')),
    IconCart: defineAsyncComponent(() => import('@/components/icons/IconCart.vue')),
    SelectAll: defineAsyncComponent(() => import('@/components/SelectAll.vue')),
    LoginPopup: defineAsyncComponent(() => import('@/views/auth/LoginPopup.vue')),
    TheProcessSteps: defineAsyncComponent(() => import('./views/TheProcessSteps.vue')),
    PreviousZakaah: defineAsyncComponent(() => import('./views/PreviousZakaah.vue')),
    SetDate: defineAsyncComponent(() => import('./components/SetDate.vue'))
  },
  name: 'Zakaah Calculator',
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD'
      },
      isLogin: false,
      isAdded: false,
      isDonatePopup: false,
      zakaah: {
        name: '',
        date: '',
        zakaatAssets: {
          commodities: [],
          cashAssets: [],
          policyAssets: [],
          investmentAssets: [],
          businessAssets: [],
          debtorAssets: [],
          partnershipAssets: []
        },
        zakaatLiabilities: {
          basicLiabilities: [],
          zakaatHomeLoanLiabilities: [],
          zakaatVehicleLiabilities: []
        }
      },
      showAssets: false,
      showLiabilities: false,
      zakaatDetailID: null,
      alert: null,
      zakaahForSave: {
        zakaatPercentage: null,
        zakaatDetailID: null,
        goldRatePerOunce: null,
        silverRatePerOunce: null,
        spotPriceDate: null,
        islamicDate: null,
        goldRatePerGram: null,
        silverRatePerGram: null,
        zakaatPaid: false,
        nisab: null,
        incompleteCalculation: false,
        zakaatName: null,
        netAssets: null,
        netLiabilities: null,
        totalZakaat: 0, // this is the flag to determin if paid or not. 0 = not paid, > 0 = amount paid
        donor: {
          userID: null
        },
        commodities: null,
        investmentAssets: null,
        cashAssets: null,
        businessAssets: null,
        policyAssets: null,
        debtorAssets: null,
        partnershipAssets: null,
        basicLiabilities: null,
        zakaatVehicleLiabilities: null,
        zakaatHomeLoanLiabilities: null
      },
      saveStatus: 'Save for later',
      isDonating: false,
      selectedProjectId: null,
      donation: {
        amount: '',
        currency: process.env.VUE_APP_CURRENCY_SYMBOL,
        projectId: null,
        donationTypes: []
      },
      selectedProject: {},
      selectedParentProject: {},
      selectedChildProject: {},
      donationType: {
        donationTypeID: 1,
        description: 'Zakaat'
      },
      hidePast: false,
      showReset: false,
      isEdit: false,
      editTotal: false
    }
  },
  mounted () {
    const theId = this.$route.params.id
    if (theId !== 'new') {
      this.isEdit = true
      this.editPast(this.pastZakaat[parseInt(theId)])
    }
    if (this.isUK) {
      this.selectedProjectId = 9
    } else {
      this.selectedProjectId = 28
    }
  },
  beforeMount () {
    this.clearCommodities()
    this.clearMoney()
    this.clearInvestments()
    this.clearPolicies()
    this.clearBusiness()
    this.clearPartnership()
    this.clearDebtors()
    this.clearGeneral()
    this.clearHome()
    this.clearVehicle()
    this.fetchPastZakaahs()
    const today = new Date().getTime()
    const date = DateTime.fromMillis(parseInt(today))
    this.today = date.toISODate()
    this.selectedDate = date.toISODate()
  },
  watch: {
    selectedDate (val) {
      this.updateDate(val)
    },
    zakaah: {
      deep: true,
      handler (val) {
        this.setZakaah(this.zakaah)
      }
    },
    selectedProject () {
      if (this.selectedProject) {
        this.donation.projectId = this.selectedProject.projectID
      }
    },
    selectedParentProject () {
      if (this.selectedChildProject && Object.keys(this.selectedChildProject).length > 0) {
        this.selectedChildProject = {}
      }
      this.selectedProject = this.selectedParentProject
    },
    selectedChildProject () {
      if (this.selectedChildProject && Object.keys(this.selectedChildProject).length > 0) {
        this.selectedProject = this.selectedChildProject
      }
    }
  },
  methods: {
    ...mapActions([
      'setExchangeRate',
      'setGoldSpotPricesPerOunce',
      'setSilverSpotPricesPerOunce',
      'setGoldSpotPricesPerGram',
      'setSilverSpotPricesPerGram',
      'setGregorianToHijri',
      'setCurrencies',
      'setZakaah',
      'clearCommodities',
      'clearMoney',
      'clearInvestments',
      'clearPolicies',
      'clearBusiness',
      'clearPartnership',
      'clearDebtors',
      'clearGeneral',
      'clearHome',
      'clearVehicle',
      'fetchPastZakaahs',
      'saveZakaah',
      'setFromPast',
      'addToCart'
    ]),
    setDate (val) {
      this.selectedDate = val
    },
    async updateDate (val) {
      if (DateTime.fromISO(val) > DateTime.fromISO(this.today)) {
        this.openAlert('Invalid Date Selection', `Unable To Fetch Spot Prices for ${this.selectedDate}.<br><br>Using Spot Prices For Today`)
        this.selectedDate = this.today
      } else {
        this.zakaah.date = val
        this.dateToConvert = DateTime.fromISO(val).toFormat('dd-MM-yyyy')
        await this.getRates()
        await this.setGregorianToHijri(this.dateToConvert)
        this.islamicDate = this.gregorianToHijri.data.hijri
      }
    },
    displayDate (val) {
      return DateTime.fromMillis(parseInt(val)).toFormat('yyyy-MM-dd')
    },
    displayDescription (val) {
      if (typeof val === 'string' || val instanceof String) {
        const splitItem = val.split('_')
        const arr = []
        splitItem.forEach(item => {
          const lowercase = item.toLowerCase()
          const caps = lowercase.charAt(0).toUpperCase() + lowercase.slice(1)
          arr.push(caps)
        })
        const str = arr.slice(0, arr.length).join(' ')
        return str
      }
    },
    getRates () {
      this.setExchangeRate(this.zakaah.date)
      this.setGoldSpotPricesPerOunce(this.zakaah.date)
      this.setSilverSpotPricesPerOunce(this.zakaah.date)
      this.setGoldSpotPricesPerGram(this.zakaah.date)
      this.setSilverSpotPricesPerGram(this.zakaah.date)
    },
    displayAssets () {
      this.showAssets = true
      this.hidePast = true
      document.body.style.overflow = 'hidden'
    },
    displayLiabilities () {
      this.showLiabilities = true
      this.hidePast = true
      document.body.style.overflow = 'hidden'
    },
    closeSection () {
      this.showAssets = false
      this.showLiabilities = false
      document.body.style.overflow = ''
    },
    openAlert (title, message, align = 'center') {
      this.alert = {
        title: title,
        message: message,
        align: align
      }
      document.body.style.overflow = 'hidden'
    },
    closeAlert () {
      this.alert = null
      document.body.style.overflow = ''
    },
    async usePast (val) {
      val.commodities.forEach(asset => {
        asset.commodityID = 0
      })
      val.cashAssets.forEach(asset => {
        asset.cashAssetID = 0
      })
      val.investmentAssets.forEach(asset => {
        asset.investmentAssetID = 0
      })
      val.businessAssets.forEach(asset => {
        asset.businessAssetID = 0
      })
      val.policyAssets.forEach(asset => {
        asset.policyAssetID = 0
      })
      val.debtorAssets.forEach(asset => {
        asset.debtorAssetID = 0
      })
      val.partnershipAssets.forEach(asset => {
        asset.partnershipAssetID = 0
      })
      val.basicLiabilities.forEach(liability => {
        liability.basicLiabilityID = 0
      })
      val.zakaatVehicleLiabilities.forEach(liability => {
        liability.zakaatVehicleLiabilityID = 0
      })
      val.zakaatHomeLoanLiabilities.forEach(liability => {
        liability.zakaatHomeLoanLiabilityID = 0
      })
      const fromPast = this.zakaah
      fromPast.zakaatAssets.commodities = val.commodities
      fromPast.zakaatAssets.cashAssets = val.cashAssets
      fromPast.zakaatAssets.investmentAssets = val.investmentAssets
      fromPast.zakaatAssets.businessAssets = val.businessAssets
      fromPast.zakaatAssets.policyAssets = val.policyAssets
      fromPast.zakaatAssets.debtorAssets = val.debtorAssets
      fromPast.zakaatAssets.partnershipAssets = val.partnershipAssets
      fromPast.zakaatLiabilities.basicLiabilities = val.basicLiabilities
      fromPast.zakaatLiabilities.zakaatVehicleLiabilities = val.zakaatVehicleLiabilities
      fromPast.zakaatLiabilities.zakaatHomeLoanLiabilities = val.zakaatHomeLoanLiabilities
      await this.setZakaah(fromPast)
      await this.setFromPast()
      this.hidePast = true
    },
    async editPast (val) {
      this.zakaah.name = val.zakaatName
      const fromPast = this.zakaah
      fromPast.zakaatAssets.commodities = val.commodities
      fromPast.zakaatAssets.cashAssets = val.cashAssets
      fromPast.zakaatAssets.investmentAssets = val.investmentAssets
      fromPast.zakaatAssets.businessAssets = val.businessAssets
      fromPast.zakaatAssets.policyAssets = val.policyAssets
      fromPast.zakaatAssets.debtorAssets = val.debtorAssets
      fromPast.zakaatAssets.partnershipAssets = val.partnershipAssets
      fromPast.zakaatLiabilities.basicLiabilities = val.basicLiabilities
      fromPast.zakaatLiabilities.zakaatVehicleLiabilities = val.zakaatVehicleLiabilities
      fromPast.zakaatLiabilities.zakaatHomeLoanLiabilities = val.zakaatHomeLoanLiabilities
      this.zakaatDetailID = val.zakaatDetailID
      await this.setZakaah(fromPast)
      await this.setFromPast()
      this.hidePast = true
    },
    async save () {
      this.saveStatus = 'Saving...'
      let user = {}
      if (this.user) {
        user = this.user
      } else {
        user = {
          userID: 203,
          firstName: '',
          lastName: ''
        }
      }
      this.zakaahForSave.zakaatPercentage = 2.5
      this.zakaahForSave.zakaatDetailID = 0
      if (this.isEdit) {
        this.zakaahForSave.zakaatDetailID = this.zakaatDetailID
      }
      this.zakaahForSave.goldRatePerOunce = this.goldRatePerOunce
      this.zakaahForSave.silverRatePerOunce = this.silverRatePerOunce
      this.zakaahForSave.spotPriceDate = new Date(this.zakaah.date)
      this.zakaahForSave.islamicDate = this.islamicDateSaveFormat
      this.zakaahForSave.goldRatePerGram = this.goldRatePerGram
      this.zakaahForSave.silverRatePerGram = this.silverRatePerGram
      this.zakaahForSave.zakaatPaid = false
      this.zakaahForSave.nisab = this.nisab
      this.zakaahForSave.incompleteCalculation = false
      this.zakaahForSave.zakaatName = this.zakaah.name
      this.zakaahForSave.netAssets = this.zakaatAssetsTotal
      this.zakaahForSave.netLiabilities = this.zakaatLiabilitiesTotal
      this.zakaahForSave.totalZakaat = this.totalPayable // this is the flag to determin if paid or not. 0 = not paid, > 0 = amount paid
      this.zakaahForSave.donor.userID = user.userID
      this.zakaahForSave.commodities = this.currentZakaah.zakaatAssets.commodities
      this.zakaahForSave.cashAssets = this.currentZakaah.zakaatAssets.cashAssets
      this.zakaahForSave.investmentAssets = this.currentZakaah.zakaatAssets.investmentAssets
      this.zakaahForSave.businessAssets = this.currentZakaah.zakaatAssets.businessAssets
      this.zakaahForSave.policyAssets = this.currentZakaah.zakaatAssets.policyAssets
      this.zakaahForSave.debtorAssets = this.currentZakaah.zakaatAssets.debtorAssets
      this.zakaahForSave.partnershipAssets = this.currentZakaah.zakaatAssets.partnershipAssets
      this.zakaahForSave.basicLiabilities = this.currentZakaah.zakaatLiabilities.basicLiabilities
      this.zakaahForSave.zakaatVehicleLiabilities = this.currentZakaah.zakaatLiabilities.zakaatVehicleLiabilities
      this.zakaahForSave.zakaatHomeLoanLiabilities = this.currentZakaah.zakaatLiabilities.zakaatHomeLoanLiabilities
      await this.saveZakaah(this.zakaahForSave)
      this.saveStatus = 'Saved'
      setTimeout(() => {
        this.saveStatus = 'Save for later'
      }, 4000)
    },
    async donateStep () {
      this.donation.amount = parseFloat(this.totalPayable)
      this.getProject(this.selectedProjectId)
      this.isDonatePopup = true
    },
    async getProject (id) {
      const theProject = this.availableAdminProjects.filter(project => {
        if (project.projectID !== id) {
          return false
        }
        return true
      })
      if (theProject[0].parentProjectID === 0) {
        this.selectedParentProject = theProject[0]
      } else {
        await this.getParentProject(theProject[0].parentProjectID)
        this.selectedChildProject = theProject[0]
      }
    },
    getParentProject (id) {
      const theProject = this.availableAdminProjects.filter(project => {
        if (project.projectID !== id) {
          return false
        }
        return true
      })
      this.selectedParentProject = theProject[0]
    },
    async donate () {
      this.isDonating = true
      await this.save()
      await this.addDonationToCart()
      this.isDonating = false
      await this.$router.push('/checkout/0')
    },
    addDonationToCart () {
      this.selectedProject.projectDonationTypeMaps.forEach((dType) => {
        const obj = { type: dType, amount: null }
        if (dType.donationType.donationTypeID !== 1) {
          this.donation.donationTypes.push(obj)
        } else {
          this.donation.donationTypes.push({ type: dType, amount: this.donation.amount })
        }
      })
      this.donation.projectId = this.selectedProject.projectID
      this.addToCart(this.donation)
      this.isDonatePopup = false
      this.isAdded = true
      this.donation = {
        amount: '',
        currency: this.defaultCurrency,
        projectId: null,
        donationTypes: []
      }
    },
    reset () {
      this.showReset = true
    },
    resetAll () {
      this.hidePast = false
      this.clearCommodities()
      this.clearMoney()
      this.clearInvestments()
      this.clearPolicies()
      this.clearBusiness()
      this.clearPartnership()
      this.clearDebtors()
      this.clearGeneral()
      this.clearHome()
      this.clearVehicle()
      this.zakaah.name = ''
      this.zakaah.zakaatAssets.commodities = []
      this.zakaah.zakaatAssets.cashAssets = []
      this.zakaah.zakaatAssets.policyAssets = []
      this.zakaah.zakaatAssets.investmentAssets = []
      this.zakaah.zakaatAssets.businessAssets = []
      this.zakaah.zakaatAssets.debtorAssets = []
      this.zakaah.zakaatAssets.partnershipAssets = []
      this.zakaah.zakaatLiabilities.basicLiabilities = []
      this.zakaah.zakaatLiabilities.zakaatHomeLoanLiabilities = []
      this.zakaah.zakaatLiabilities.zakaatVehicleLiabilities = []
      this.showReset = false
      window.scroll(0, 0)
    }
  },
  computed: {
    ...mapGetters([
      'exchangeRate',
      'goldRatePerOunce',
      'silverRatePerOunce',
      'goldRatePerGram',
      'silverRatePerGram',
      'gregorianToHijri',
      'user',
      'zakaatAssets',
      'zakaatLiabilities',
      'currentZakaah',
      'pastZakaat',
      'commoditiesTotal',
      'cashAssetsTotal',
      'policyAssetsTotal',
      'investmentAssetsTotal',
      'businessAssetsTotal',
      'debtorAssetsTotal',
      'partnershipAssetsTotal',
      'zakaatLiabilitiesTotal',
      'basicLiabilitiesTotal',
      'zakaatHomeLoanLiabilitiesTotal',
      'zakaatVehicleLiabilitiesTotal',
      'zakaatAssetsTotal',
      'adminProjects',
      'contentProjects'
    ]),
    availableAdminProjects () {
      return this.adminProjects.filter(project => {
        let ret = false
        project.projectDonationTypeMaps.forEach((donationType) => {
          if (donationType.donationType.donationTypeID === 1) {
            ret = true
          }
        })
        if (ret === false) {
          return false
        }
        return true
      })
    },
    parentProjects () {
      return this.availableAdminProjects.filter(project => {
        if (project.parentProjectID !== 0) {
          return false
        }
        return true
      })
    },
    childProjects () {
      const arr = this.availableAdminProjects.filter(project => {
        if (this.selectedParentProject && project.parentProjectID !== this.selectedParentProject.projectID) {
          return false
        }
        return true
      })
      arr.sort((a, b) => (a.description > b.description ? 1 : -1))
      return arr
    },
    islamicDateFormat () {
      if (!this.islamicDate) return ''
      return this.islamicDate.day + ' ' + this.islamicDate.month.en + ' ' + this.islamicDate.year
    },
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    },
    islamicDateSaveFormat () {
      if (!this.islamicDate) return ''
      return this.islamicDate.year + '-' + (this.islamicDate.month.number < 10 ? '0' + this.islamicDate.month.number : this.islamicDate.month.number) + '-' + this.islamicDate.day
    },
    totalAssetsDisplay () {
      return parseFloat(Math.round((this.zakaatAssetsTotal) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    totalLiabilitiesDisplay () {
      return parseFloat(Math.round((this.zakaatLiabilitiesTotal) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    nisab () {
      return 612.36 * this.silverRatePerGram
    },
    nisabDisplay () {
      return parseFloat(Math.round((612.36 * this.silverRatePerGram) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    total () {
      return this.zakaatAssetsTotal - this.zakaatLiabilitiesTotal
    },
    totalDisplay () {
      return parseFloat(Math.round((this.total) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    totalPayable () {
      return (this.total > this.nisab ? ((this.total * 0.025).toFixed(2)) : 0.00)
    },
    totalPayableDisplay () {
      return parseFloat(Math.round((this.totalPayable) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }
  }

}
</script>

<style scoped>
.disclaimer {
  font-size: 0.7rem;
}
.heading {
  color: var( --green-color-dark );
  font-family: "quicksand_medium", Sans-serif;
  font-size: 44px;
  font-weight: 600;
  line-height: 1.5em;
}
.smallheading {
  color: var( --green-color );
  font-family: "quicksand_medium", Sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
}
.divider {
  width: 1px;
  height: 100%;
  background-color: var(--green-color-light);
  background-color: #dee2e6;
}
.letter-spacing {
  letter-spacing: 4px;
}

</style>
