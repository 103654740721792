<template>
  <div class="row m-0">
    <div class="col-12 p-0">
      <div class="container-lg" >
        <PageTitle :title="$route.name" />
        <div class="row mx-0 justify-content-center page_top">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center">
              <div class="col-11 col-sm-10 col-md-8 col-lg-7 col-xl-7 col-xxl-6 p-0 my-5 card_item">
                <div class="row mx-0 h-100 justify-content-center">
                  <div class="col-12 p-0">
                    <ContentWithBackground class="rounded">
                      <div class="row m-0 justify-content-center mb-4">
                        <div class="col-12 bold">
                          What is Gift Aid?
                        </div>
                        <div class="col-12">
                          Gift Aid is a government scheme which is open to all charity supporters who pay UK Income and Capital Gains tax. For every pound you earn you pay 25p (or 20% basic rate of tax). By signing up to Gift Aid, every time you donate your charity can reclaim the tax you have paid based on the value of the donation you have made. So, a £10 donation from you enables charities to reclaim £2.50. Charities are very dependent on Gift Aid.
                        </div>
                      </div>
                      <div class="row m-0 justify-content-center mb-4">
                        <div class="col-12 bold">
                          Am I eligible to sign up for Gift Aid?
                        </div>
                        <div class="col-12">
                          You're eligible if you're a UK tax payer living anywhere in the world. However, you must have paid enough tax to cover the amount that all the charities you support would claim in Gift Aid in a tax year (6 April – 5 April the following year). Even if you're not employed, you're still eligible for Gift Aid if you pay tax on any of the following:
                          <ul class="my-4">
                            <li>a personal or occupational pension</li>
                            <li>bank or building society savings accounts</li>
                            <li>rental income</li>
                            <li>stocks and shares</li>
                            <li>UK and overseas investment dividends</li>
                          </ul>
                        </div>
                        <div class="col-12 mb-4">
                          Other taxes such as VAT and council tax do not apply.
                        </div>
                        <div class="col-12">
                          As the tax payer, you're responsible for checking your eligibility for Gift Aid as any tax shortfall is the responsibility of the tax payer, so please speak to your accountant or HMRC if you are unclear on how much tax you have paid.
                        </div>
                      </div>
                      <div class="row m-0 justify-content-center mb-4">
                        <div class="col-12 bold">
                          How much tax do I need to pay to be eligible for Gift Aid?
                        </div>
                        <div class="col-12">
                          In one financial year you must have paid at least 25p in income or capital gains tax for every £1 you donate to charity. Every Income Tax payer has a tax-exempt threshold before they start paying tax. So please ensure you are paying enough tax to Gift Aid your donations.
                        </div>
                      </div>
                      <div class="row m-0 justify-content-center mb-4">
                        <div class="col-12 bold">
                          Who can claim Gift Aid?
                        </div>
                        <div class="col-12">
                          Only an individual tax payer can claim Gift Aid. Neither businesses, crowd donations nor joint-donors (e.g. husband and wife) can claim.
                        </div>
                      </div>
                      <div class="row m-0 justify-content-center mb-4">
                        <div class="col-12 bold">
                          Can I Gift Aid my donations if I live overseas?
                        </div>
                        <div class="col-12">
                          Yes, if you still pay some form of UK income or capital gains tax.
                        </div>
                      </div>
                      <div class="row m-0 justify-content-center mb-4">
                        <div class="col-12 bold">
                          How do I sign up for Gift Aid?
                        </div>
                        <div class="col-12">
                          In order for us to claim Gift Aid on your donations you must provide us with your consent. The consent can be given by ticking the relevant box at time of donating online; verbally over the telephone or by completing a Gift Aid declaration form and returning to us.
                        </div>
                      </div>
                      <div class="row m-0 justify-content-center mb-4">
                        <div class="col-12 bold">
                          How long does the declaration last for?
                        </div>
                        <div class="col-12">
                          The declaration normally covers past, present and future donations. In respect to the past, this means we can make a back-claim of four years.
                        </div>
                      </div>
                      <div class="row m-0 justify-content-center mb-4">
                        <div class="col-12 bold">
                          How do I know if I pay UK income or capital gains tax?
                        </div>
                        <div class="col-12">
                          If any of the following applies to you:
                          <ul class="my-4">
                            <li>income tax is deducted from your salary before you receive it</li>
                            <li>you pay tax after submitting a self-assessment form each year</li>
                            <li>you have a taxable pension plan, savings (eg with a building society) or investment income</li>
                            <li>you have paid capital gains tax (eg on the sale of a property or share)</li>
                          </ul>
                        </div>
                        <div class="col-12">
                          If you're unsure of your eligibility for Gift Aid, please contact HMRC.
                        </div>
                      </div>
                      <div class="row m-0 justify-content-center mb-4">
                        <div class="col-12 bold">
                          What if I pay the higher rate of income tax?
                        </div>
                        <div class="col-12">
                          If you are taxed at the higher rate of 40%, we can still only reclaim tax at the basic rate of 20%. However, higher rate taxpayers can also claim the difference between the higher tax rate (40%) and the basic rate (20%) on the total (gross) value of a donation made. If you pay Income Tax at the higher or additional rate and want to receive the additional tax relief due to you, you must include all your Gift Aid donations on your Self-Assessment tax return or ask HM Revenue and Customs to adjust your tax code. Please refer to <a href="https://www.gov.uk/donating-to-charity/gift-aid" target="_blank">https://www.gov.uk/donating-to-charity/gift-aid</a>.
                        </div>
                      </div>
                      <div class="row m-0 justify-content-center mb-4">
                        <div class="col-12 bold">
                          How would Gift Aid affect my tax?
                        </div>
                        <div class="col-12">
                          Signing up to Gift Aid doesn't affect the tax you pay. We make the claim to HMRC on the tax you have already paid on your donations.
                        </div>
                      </div>
                      <div class="row m-0 justify-content-center mb-4">
                        <div class="col-12 bold">
                          What if I donate to several charities and want to Gift Aid to all of them?
                        </div>
                        <div class="col-12">
                          You can do this by signing a declaration for each charity. As long as each tax year you will have paid at least the equivalent amount of tax that all the charities combined will be claiming, Gift Aid can be applied. It's your responsibility to repay any difference.
                        </div>
                      </div>
                      <div class="row m-0 justify-content-center mb-4">
                        <div class="col-12 bold">
                          What if I don't pay, or stop paying, UK tax?
                        </div>
                        <div class="col-12">
                          We are unfortunately unable to claim Gift Aid on your donation if you do not pay tax. Therefore, if your situation changes in the future, please inform us via email on giftaid@alimdaad.co.uk
                        </div>
                      </div>
                      <div class="row m-0 justify-content-center mb-4">
                        <div class="col-12 bold">
                          Who will be able to access my Gift Aid information?
                        </div>
                        <div class="col-12">
                          Only HMRC and Al-Imdaad Foundation UK. We do not share your information with anyone else, not even other family members, without your permission.
                        </div>
                      </div>
                      <div class="row m-0 justify-content-center mt-4">
                        <div class="col-12 bold">
                          Resources:
                        </div>
                        <div class="col-12">
                          <a href="https://www.gov.uk/donating-to-charity/gift-aid" target="_blank">www.gov.uk/donating-to-charity</a>
                        </div>
                        <div class="col-12">
                          <a href="https://www.gov.uk/tax-limited-company-gives-to-charity" target="_blank">www.gov.uk/tax-limited-company-gives-to-charity</a>
                        </div>
                      </div>
                    </ContentWithBackground>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LatestAppealsWithSlider />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    ContentWithBackground: defineAsyncComponent(() => import('@/components/styling/ContentWithBackground.vue')),
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    LatestAppealsWithSlider: defineAsyncComponent(() => import('@/components/LatestAppealsWithSlider.vue'))
  },
  name: 'GiftAid'
}
</script>

<style scoped>
.card_item {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 5px 5px 15px 0px rgb(0 0 0 / 9%);
  box-shadow: rgba(0, 0, 0, 0.09) 5px 5px 10px 0px;
}
a, a:visited {
  text-decoration: underline;
  color: var(--green-color-light);
}
a:hover {
  color: var(--green-color-lighter);
}
</style>
